<!-- <cookie-message></cookie-message> -->
<br /><br />

<div class="container text-align-center" style="width: 100%;">

    <div class="col-6 text-align-center no" style="float: none;">
        <register id="register"></register>
    </div>

<div class="clearfix"></div>
