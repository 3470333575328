
<div class="home-page-form">
    <div class="home-sitting-input" ng-if="pageVisible()">
        <div class="input-container">
            <h1 ng-if="registerInputVisible()">{{'REGISTER_HEADER' | translate}}</h1><br>
            <div ng-if="registerInputVisible()" ng-bind-html="'REGISTER_BLURB' | translate"></div>
            <div id="fresh-approach-container">
                <div id="fresh-approach" ng-bind-html="'FRESH_APPROACH' | translate"></div>
            </div>
            <!-- <div id="seasonal-message-container">
                <div id="seasonal-message" ng-bind-html="'SEASONAL_MESSAGE' | translate"></div>
            </div> -->
            <!-- Sitting input -->
            <div ng-if="sittingInputVisible()">
                <input id="sitting-input" type="text" data-ng-model="dto.sittingPassword" placeholder="{{'HOME_HEADER_LABEL' | translate}}" ng-change="checkFormRefresh('sitting')" />
                <div class="margin-bottom-10">
                    <p class="alert" ng-if="showInvalidSittingMessage">{{'INVALID_SITTING_ID' | translate}}</p>
                    <p class="alert" id="not-ready-sitting-message" ng-if="showNotReadySittingMessage" ng-bind-html="'NOTIFYME_BODY' | translate"></p>
                    <a class="alert not-ready-sitting-message-link" ng-click="toggleNotifymeInfo()" ng-if="showNotReadySittingMessage">{{'NOTIFYME_BODY_INFO_LINK' | translate}}</a>
                    <p class="alert" id="not-ready-sitting-message-info" ng-if="showNotifymeInfo" ng-bind-html="'NOTIFYME_INFO' | translate"></p>
                </div>
            </div>
            <!-- Email input -->
            <div ng-show="emailInputVisible()">
                <span class="email-container">
                    <input id="email-input" type="text" data-ng-model="dto.emailAddress" placeholder="{{'ENTER_YOUR_EMAIL_ADDRESS' | translate}}" ng-change="checkFormRefresh('email')" ng-disabled="emailInputDisabled()" />
                    <span class="show-blurb-button fa fa-question-circle" ng-click="toggleEmailBlurb()"></span>
                    <span id="email-blurb" data-ng-if="showEmailBlurb">
                        <i class="fa fa-close close-button" ng-click="toggleEmailBlurb()"></i>
                        <p><strong>{{'EMAIL_BLURB_HEADING' | translate}}</strong></p><br>
                        <p>{{'EMAIL_BLURB_BODY' | translate}}</p>
                    </span>
                </span>
                <p class="alert" ng-if="showInvalidEmailMessage">{{'ERROR_EMAIL' | translate}}</p>
            </div>
            <!-- Register inputs -->
            <div ng-if="registerInputVisible()">
                <select id="country-input" class="class-input no-mouseflow" data-ng-model="dto.country"
                    placeholder="placeholder" data-ng-options="country.name for country in countries" required>
                    <option value="" disabled selected hidden>{{'COUNTRY' | translate}}</option>
                </select>
                <p class="alert" ng-show="showCountryErrorMessage">{{'ERROR_COUNTRY' | translate}}</p>
                <input id="firstname-input" type="text" data-ng-model="dto.firstName" placeholder="{{'REGISTER_FORENAME' | translate}}" />
                <input id="surname-input" type="text" data-ng-model="dto.surname" placeholder="{{'REGISTER_SURNAME' | translate}}" />
            </div>
            <!-- Password input -->
            <div ng-if="passwordInputVisible()">
                <div>
                    <span class="password-container" ng-if="!showPasswordText">
                        <input class="no-mouseflow" type="password" placeholder="{{'LOGIN_PASSWORD' | translate}}" data-ng-model="$parent.dto.password" ng-keyup="$event.keyCode == 13 && login()" />
                        <span ng-click="changeShowPasswordText(true);" class="show-hide-button fa fa-eye"></span>
                    </span>
                    <span class="password-container" ng-if="showPasswordText">
                        <input class="no-mouseflow" type="text" placeholder="{{'LOGIN_PASSWORD' | translate}}" data-ng-model="$parent.dto.password" ng-keyup="$event.keyCode == 13 && login()" />
                        <span ng-click="changeShowPasswordText(false);" class="show-hide-button fa fa-eye-slash"></span>
                    </span>
                </div>
                <p class="alert" ng-show="showLoginMessage">{{loginMessage}}</p>
                <a ng-if="!registerInputVisible()" ng-click="resetPassword()">{{'FORGOT_PASSWORD' | translate}}</a>
            </div>
            <!-- Preferences input -->
            <div class="preferences-container" ng-if="preferencesInputVisible()">
                <div class="preference" ng-if="showNotifyMePreference">
                    <p>{{'NOTIFYME_PICTURES' | translate}} <i class="button-icon fa fa-question-circle" ng-click="toggleNotifyMeCallout()"></i></p>
                    <span><input type="checkbox" id="notifyme-pictures" data-ng-model="dto.emailNotify"></span>
                </div>
                <p class="preference-callout" style="text-align: left;" ng-show="showNotifyMeCallout">{{'NOTIFYME_PICTURES_CALLOUT' | translate}}</p>
                <div class="preference">
                    <p>{{'PRIVACY_OPTOUT' | translate}} <i class="button-icon fa fa-question-circle" ng-click="toggleOptOutCallout()"></i></p>
                    <span><input type="checkbox" id="privacy-optout" data-ng-model="dto.optOutMarketing"></span>
                </div>
                <div class="preference-callout" style="text-align: left;" ng-bind-html="'PRIVACY_OPTOUT_CALLOUT' | translate" ng-show="showOptOutCallout"></div>
            </div>
            <!-- Validation input -->
            <div ng-if="validationInputVisible()">
                <p class="alert" id="validate-email-message">{{'VALIDATE_EMAIL_MESSAGE' | translate}}</p>
                <div id="validation-help">
                    <div ng-if="!showValidationEmailNotReceived">
                        <a class="grey-link" data-ng-click="resendValidationEmail()">{{'RESEND_VALIDATION_EMAIL' | translate}}</a>
                    </div>
                    <div ng-if="showValidationEmailNotReceived">
                        <a class="grey-link" href="mailto:customerservice@pret-a-portrait.net?subject=Email%20validation%20link%20not%20received">{{'VALIDATION_LINK_NOT_RECIEVED' | translate}}</a>
                    </div>
                </div>
                <div>
                    <p class="message">{{'REVEAL_VALIDATION_INPUT' | translate}} <a data-ng-click="toggleCodeInput()">{{'HERE' | translate}}</a></p>
                </div>
                <div class="validation-code-input" ng-if="showCodeInput">
                    <input id="validation-code-0" type="text" maxlength="1" data-ng-model="validationCode[0]" ng-change="advanceFocus(0)" />
                    <input id="validation-code-1" type="text" maxlength="1" data-ng-model="validationCode[1]" ng-change="advanceFocus(1)" />
                    <input id="validation-code-2" type="text" maxlength="1" data-ng-model="validationCode[2]" ng-change="advanceFocus(2)" />
                    <input id="validation-code-3" type="text" maxlength="1" data-ng-model="validationCode[3]" ng-change="advanceFocus(3)" />
                </div>
            </div>
            <p class="alert" id="invalid-otp-message" ng-show="showInvalidOtpMessage">{{'INVALID_CODE' | translate}}</p>
        </div>
        <!-- Continue button -->
        <div ng-if="showButton">
            <button id="submit-button" data-ng-click="submit()">{{submitButtonStrings[dto.stage]}}<span data-ng-if="loading">&ensp;<i class="fa fa-spinner fa-spin"></i></span></button>
        </div>

        <div ng-if="!registerInputVisible()">
            <p class="home-cookie-message" ng-if="!showFullCookieMessage">{{'HOME_COOKIE_MESSAGE' | translate}} <a data-ng-click="toggleCookieMessage()">{{'SHOW_MORE' | translate}}...</a></p>
            <p class="home-cookie-message" ng-if="showFullCookieMessage">{{'HOME_COOKIE_MESSAGE_FULL' | translate}} <a data-ng-click="toggleCookieMessage()">{{'SHOW_LESS' | translate}}...</a></p>
        </div>

        <div id="notifyme-privacy" ng-bind-html="'NOTIFYME_PRIVACY' | translate" ng-if="showNotReadySittingMessage"></div>
    </div>
    <div ng-if="registerInputVisible()">
        <br>
        <a id="bypass-register" data-ng-click="bypassRegister()">{{'VIEW_PICTURES_WITHOUT_REGISTRATION' | translate}}</a>
    </div>
    <div class="home-registered" ng-if="!doneMessageVisible() && !registerInputVisible() && pageVisible()">
        <p>{{'ALREADY_REGISTERED' | translate}}</p>
        <input type="submit" id="login-button" value="{{'LOGIN' | translate}}" class="light-blue" data-ng-click="directLogin()"></input>
    </div>
    <div class="done-message" ng-if="doneMessageVisible()">
        <p ng-bind-html="'NOTIFYTHANKS_BODY' | translate"></p>
        <button id="go-back" data-ng-click="goBack()">{{'NOTIFYTHANKS_BACK' | translate}}</button>
    </div>
    <div class="done-message" ng-if="showInvalidTokenMessage">
        <p ng-bind-html="'INVALID_CODE' | translate"></p>
    </div>
</div>
