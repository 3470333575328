
<div ng-if="visible">
  <div fix-and-scroll size="small" class="products-list">
    <div>
      <!-- We only want to show these by default if not on mobile. -->
      <div>
        <div data-ng-show="loading" class="loading-inline fade">
          <i class="fa fa-spin fa-spinner"></i>
        </div>

        <category-products ng-show="! loading"
          modal-base="modalBase"
          current-product-category="currentProductCategory"
        ></category-products>
      </div>
    </div>
  </div>
</div>
