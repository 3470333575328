<div tabindex="0" id="{{image.id}}" class="album-products" data-ng-click=""
    data-ng-style="">
    <div tabindex="0" id="product-{{product.id}}" class="album-product" data-ng-click="launchProduct()">

        <div class="product-image" data-ng-style="{'background-image':'url(' + product.thumbnailUrl + ')'}"></div>

        <span class="name">{{product.name}}</span>

        <span class="description">{{product.shortDescription}}</span>

                <span ng-if="! product.upsellPrice" class="price-buy"><span class="blue">{{product.price}}</span></span>

                <span ng-if="product.upsellPrice" class="price-buy">
                    <span class="strikethrough">{{product.price}}</span>
                    <span class="blue">{{product.upsellPrice}}</span>
                </span>
    </div>
</div>



