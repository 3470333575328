<div class="header">
    <!-- DESKTOP / TABLET HEADER -->
    <div class="header-desktop desktop-show container">
        <div class="header-left">
            <a href="{{config.shopUrl}}"><img class="logo" src="/assets/images/logo/pret-logo-white.png" /></a>
        </div>
        <div class="header-menu">
            <a data-ng-show="isLoggedIn || hasSittings" href="/MyAlbums"><i class="button-icon fa fa-th"></i> {{'HEADER_SHOP_BUTTON' | translate}}</a>
            <a data-ng-show="isLoggedIn || hasSittings" data-ng-href="/Favourites"><i class="fa fa-heart"></i> {{'HEADER_MYFAVOURITES_BUTTON' | translate: { favouritesCount: statStore.favouriteCount} }}</a>
            <a id="menu-download" data-ng-show="isLoggedIn && statStore.downloadCount > 0" data-ng-href="/Downloads"><i class="fa fa-download"></i> {{'HEADER_MYDOWNLOADS_BUTTON' | translate: { downloadCount: statStore.downloadCount} }}</a>
            <a data-ng-show="isLoggedIn" data-ng-href="/Account" ><i class="button-icon fa fa-user"></i> {{'ACCOUNT' | translate}} </a>
            <a data-ng-show="isLoggedIn || hasSittings" data-ng-href="/Basket"><icon class="button-icon" type="basket-blue"></icon> {{'HEADER_BASKET_BUTTON' | translate: { basketCount: statStore.basketCount } }}</a>
            <a data-ng-href="{{ generateUrl('faq') }}" target="_blank"><i class="button-icon fa fa-question"></i> {{'FAQ' | translate}}</a>
            <a data-ng-show="!isLoggedIn && !isValidatedEmail" data-ng-href="/Login"><i class="button-icon fa fa-user"></i> {{'LOGIN' | translate}} </a>
            <a data-ng-show="!isLoggedIn && isValidatedEmail" data-ng-href="/Register"><i class="button-icon fa fa-user"></i> {{'LOGIN' | translate}} </a>
            <a data-ng-show="isLoggedIn" data-ng-click="logout()"><i class="button-icon fa fa-user"></i> {{'LOGOUT_BUTTON' | translate}} </a>
            <language></language>
        </div>
    </div>

    <!-- MOBILE HEADER -->
    <div class="header-mobile desktop-hide">
        <a href="{{config.shopUrl}}"><img class="logo" src="/assets/images/logo/pret-logo-white.png" /></a>

        <div class="header-menu desktop-hide">
            <a data-ng-href="/MyAlbums"><i class="fa fa-th"></i></a>
            <a data-ng-href="/Basket"><i class="fa fa-shopping-basket"></i> {{statStore.basketCount}}</a>
            <a class="menu-button" data-ng-click="toggleMenu()" data-ng-class="{ 'open': mobileMenu.open }"><i class="fa" data-ng-class="{ 'fa-bars': !mobileMenu.open, 'fa-close': mobileMenu.open }"></i></a>
        </div>

        <div class="menu-mobile">
			<ul class="menu-list-mobile animate" data-ng-class="{ 'open': mobileMenu.open, 'closed': !mobileMenu.open }">
				<li data-ng-if="isLoggedIn || hasSittings"><a class="button" data-ng-click="toggleMenu()" href="/MyAlbums"><i class="button-icon fa fa-th"></i> {{'HEADER_SHOP_BUTTON' | translate}}</a></li>
				<li data-ng-if="isLoggedIn"><a class="button" data-ng-click="toggleMenu()" data-ng-href="/Account"><i class="button-icon fa fa-user"></i> {{'ACCOUNT' | translate}} </a></li>
                <li data-ng-show="isLoggedIn || hasSittings"><a class="button" data-ng-click="toggleMenu()" data-ng-href="/Favourites"><i class="button-icon fa fa-heart"></i> {{'HEADER_MYFAVOURITES_BUTTON' | translate: { favouritesCount: statStore.favouriteCount} }}</a></li>
                <li data-ng-if="isLoggedIn || hasSittings"><a class="button" data-ng-click="toggleMenu()" data-ng-href="/Basket"><icon class="button-icon" type="basket-white"></icon> {{'HEADER_BASKET_BUTTON' | translate: { basketCount: statStore.basketCount } }}</a></li>
                <li data-ng-if="statStore.downloadCount"><a class="button" data-ng-show="isLoggedIn" data-ng-href="/Downloads"><i class="button-icon fa fa-download"></i> {{'HEADER_MYDOWNLOADS_BUTTON' | translate: { downloadCount: statStore.downloadCount} }}</a></li>
                <li><a class="button" data-ng-click="toggleMenu()" data-ng-href="{{ generateUrl('faq') }}" target="_blank"><i class="button-icon fa fa-question"></i> {{'FAQ' | translate}}</a></li>
                <li data-ng-if="!isLoggedIn && !isValidatedEmail"><a class="button" data-ng-click="toggleMenu()" data-ng-href="/Login"><i class="button-icon fa fa-user"></i> {{'LOGIN' | translate}} </a></li>
                <li data-ng-if="!isLoggedIn && isValidatedEmail"><a class="button" data-ng-click="toggleMenu()" data-ng-href="/Register"><i class="button-icon fa fa-user"></i> {{'LOGIN' | translate}} </a></li>
                <li data-ng-if="isLoggedIn"><a class="button" data-ng-click="logout()"><i class="button-icon fa fa-user"></i> {{'LOGOUT_BUTTON' | translate}} </a></li>
                <li class="language-li bottom-border"><language></language></li>
                <li><a ng-href="{{ generateUrl('our-services') }}" target="_blank" class="button" data-ng-click="toggleMenu()"><i class="button-icon fa fa-camera"></i><span class="">{{ 'OUR_SERVICES' | translate }}</span></a></li>
                <li><a ng-href="{{ generateUrl('contact') }}" target="_blank" class="button" data-ng-click="toggleMenu()"><i class="button-icon fa fa-envelope"></i><span class="">{{ 'CONTACT_US' | translate }}</span></a></li>
                <!-- <li><a ng-href="{{generateFeedbackUrl()}}" target="_blank" class="button" data-ng-click="toggleMenu()"><i class="button-icon fa fa-comments-o"></i> <span class="">{{'FEEDBACK' | translate}}</span></a></li> -->
                <li><a ng-if="! config.hideTerms" ng-href="{{ generateUrl('terms') }}" target="_blank" class="button" data-ng-click="toggleMenu()"><i class="button-icon fa fa-pencil"></i><span class="">{{ 'TERMS' | translate }}</span></a></li>
                <li><a ng-if="! config.hidePrivacy" ng-href="{{ generateUrl('privacy-statement') }}" target="_blank" class="button" data-ng-click="toggleMenu()"><i class="button-icon fa fa-lock"></i><span class="">{{ 'PRIVACY' | translate }}</span></a></li>
			</ul>
        </div>
    </div>

    <banner></banner>
</div>

