
<!-- Next job -> Add the two pane / 1 pane framework back in. -->

<load wait-for="loadingPage">

    <mobile-summary ng-if="model" summary-data="model.summary"></mobile-summary>

    <div id="paymentForm">

            <div class="col-6">

                <input type="hidden" name="lang" value="{{language}}" />

                <checkout-container model="model" update-model="updateModel" ng-if="model">

                    <checkout-module modules="model.checkoutModules" module="DELIVERY_ADDRESS">

                        <tab-bar model="model" default-tab="getDefaultTabIndex()">

                            <tab label="'TO_SCHOOL'" ng-hide="!model.deliverToSchool">
                                <address-module property="eventAddress"
                                                set-property="shippingAddressId"
                                                settable="false"
                                                on-continue="checkSame()"
                                                >
                                </address-module>
                            </tab>

                            <tab label="'TO_ELSEWHERE_' + model.deliverToSchool">
                                <address-module property="otherShippingAddress"
                                                set-property="shippingAddressId"
                                                settable="true"
                                                filter-by-country="true"
                                                on-continue="checkSame()">
                                </address-module>
                            </tab>
                        </tab-bar>

                    </checkout-module>

                    <checkout-module modules="model.checkoutModules" module="BILLING_ADDRESS">

                        <same-as-module
                            ng-if="containsModule('DELIVERY_ADDRESS')"
                            source-property="shippingAddressId"
                            target-property="invoiceAddressId"
                            same-as="bind.sameAddress"></same-as-module>

                        <address-module
                            ng-if="(! containsModule('DELIVERY_ADDRESS')) || !bind.sameAddress"
                            property="selectedInvoiceAddress"
                            set-property="invoiceAddressId"
                            set-if-property="shippingAddressId"
                            set-if-condition="downloadOnly"
                            settable="true"
                            on-continue="setAddress">
                        </address-module>
                    </checkout-module>

                    <checkout-module modules="model.checkoutModules" module="ADDITIONAL_INFORMATION">
                        <additional-module></additional-module>
                    </checkout-module>

                    <checkout-module modules="model.checkoutModules" module="TERMS">
                        <terms-and-conditions-module></terms-and-conditions-module>
                    </checkout-module>

                    <checkout-module modules="model.checkoutModules" module="PAYMENT">
                        <payment-module></payment-module>
                    </checkout-module>

                </checkout-container>
            </div>
    </div>


    <summary ng-if="model" summary-data="model.summary"></summary>
</load>
