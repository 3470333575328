<div data-ng-if="showFeedbackForm" class="feedback">
	<div class="col-5">
		<translate>FEEDBACK_BLURB</translate>
	</div>
	<div class="col-6" style="float:right;">
		<div class="form-group">
			<label>{{'FEEDBACK_FORM_NAME' | translate}} *</label>
            <input type="text" data-ng-model="form.name" ng-change="validateName(form.name)" />
		</div>
        <p class="alert" ng-show="invalidName">{{'ERROR_FULLNAME' | translate}}</p>

		<div class="form-group">
			<label>{{'FEEDBACK_FORM_EMAIL' | translate}} *</label>
			<input pattern="^[^@]+@[^@]+\.[^@]+$" type="email" data-ng-model="form.email" ng-change="validateEmail(form.email)" />
		</div>
        <p class="alert" ng-if="invalidEmail">{{'ERROR_EMAIL' | translate}}</p>

		<div class="form-group">
			<label>{{'FEEDBACK_FORM_SITTING' | translate}}</label>
			<input type="text" data-ng-model="form.sittingId" />
		</div>

		<div class="form-group">
			<p>{{'FEEDBACK_EXPERIENCE_TITLE' | translate}} </p>
			<span class="radio" data-ng-click="setExperience(1);">
				{{'FEEDBACK_EXPERIENCE_POOR' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{
				   'fa-circle-o': form.experienceRating != 1,
				   'fa-check-circle-o': form.experienceRating == 1
			   }"></i>
			</span>

			<span class="radio" data-ng-click="setExperience(2);">
				{{'FEEDBACK_EXPERIENCE_BELOWADEQUATE' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{
				   'fa-circle-o': form.experienceRating != 2,
				   'fa-check-circle-o': form.experienceRating == 2
			   }"></i>
			</span>

			<span class="radio" data-ng-click="setExperience(3);">
				{{'FEEDBACK_EXPERIENCE_ADEQUATE' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{
				   'fa-circle-o': form.experienceRating != 3,
				   'fa-check-circle-o': form.experienceRating == 3
			   }"></i>
			</span>

			<span class="radio" data-ng-click="setExperience(4);">
				{{'FEEDBACK_EXPERIENCE_GOOD' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{
				   'fa-circle-o': form.experienceRating != 4,
				   'fa-check-circle-o': form.experienceRating == 4
			   }"></i>
			</span>

			<span class="radio" data-ng-click="setExperience(5);">
				{{'FEEDBACK_EXPERIENCE_EXCELLENT' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{'
				   fa-circle-o': form.experienceRating != 5,
				   'fa-check-circle-o': form.experienceRating == 5
			   }"></i>
			</span>
		</div>

		<div class="form-group">
			<label class="textarea top">{{'FEEDBACK_EXPERIENCE_MOREINFO' | translate}}</label>
			<textarea data-ng-model="form.experience"></textarea>
		</div>

		<div class="form-group">
			<p>{{'FEEDBACK_WEBSITEEXPERIENCE_TITLE' | translate}} </p>
			<span class="radio" data-ng-click="setWebsiteRating(1);">
				{{'FEEDBACK_EXPERIENCE_POOR' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{
				   'fa-circle-o': form.websiteRating != 1,
				   'fa-check-circle-o': form.websiteRating == 1
			   }"></i>
			</span>

			<span class="radio" data-ng-click="setWebsiteRating(2);">
				{{'FEEDBACK_EXPERIENCE_BELOWADEQUATE' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{
				   'fa-circle-o': form.websiteRating != 2,
				   'fa-check-circle-o': form.websiteRating == 2
			   }"></i>
			</span>

			<span class="radio" data-ng-click="setWebsiteRating(3);">
				{{'FEEDBACK_EXPERIENCE_ADEQUATE' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{
				   'fa-circle-o': form.websiteRating != 3,
				   'fa-check-circle-o': form.websiteRating == 3
			   }"></i>
			</span>

			<span class="radio" data-ng-click="setWebsiteRating(4);">
				{{'FEEDBACK_EXPERIENCE_GOOD' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{
				   'fa-circle-o': form.websiteRating != 4,
				   'fa-check-circle-o': form.websiteRating == 4
			   }"></i>
			</span>

			<span class="radio" data-ng-click="setWebsiteRating(5);">
				{{'FEEDBACK_EXPERIENCE_EXCELLENT' | translate}}:
				<i class="fa checkable"
				   data-ng-class="{'
				   fa-circle-o': form.websiteRating != 5,
				   'fa-check-circle-o': form.websiteRating == 5
			   }"></i>
			</span>
		</div>

		<div class="form-group">
			<label class="textarea top">{{'FEEDBACK_WEBSITEEXPERIENCE_MOREINFO' | translate}}</label>
			<textarea data-ng-model="form.website"></textarea>
		</div>

		<div class="form-group">
			<button data-ng-disabled="!isValid() || submittingForm" data-ng-click="submit()"><span data-ng-if="submittingForm"><i class="fa fa-spinner fa-spin"></i> </span>{{'BUTTON_SUBMIT' | translate}}</button>
		</div>
	</div>
</div>

<div data-ng-if="!showFeedbackForm">
	<h1>{{'THANKS' | translate}}</h1>
	<p>{{'FEEDBACK_SUCCESS' | translate}}</p>
</div>