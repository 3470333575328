
<div id="banner-main" class="banner">

	<div class="container text-align-center">

		<div class="banner-top">
			<ul class="banner-categories" data-ng-if="showCategories()">
				<li class="product-list-category-header"
						data-ng-repeat="category in categories"
						ng-click="navigateCategory(category)"
						data-ng-class="{'active': isCategoryCurrentCategory(category), 'disable': disableCategories }">
						<div class="vertical-align-middle">
						<p class="product-name">{{category.name}}</p>
						</div>
				</li>
			</ul>

			<div data-ng-if="showBannerTitle()" class="banner-title">{{routeService.get().name | translate:routeService.get().params}}</div>
		</div>

		<image-folders selected="currentAlbumId" modal="false" ng-if="(routeService.get().name == 'ALBUM' || routeService.get().name == 'FAVOURITES' || routeService.get().name == 'PACKS' || routeService.get().name == 'FAVS')"></image-folders>

	</div>
</div>

<!-- {{routeService.get()}}
{{routeService.getPreviousRoute()}} -->

<a data-ng-if="(routeService.get().name == 'ALBUM' || routeService.get().name == 'PREVIEW') && $route.current.params.pictureId && (routeService.get().fullName.toUpperCase().indexOf('FAVOURITES') == -1)"
data-ng-href="/Album/{{$route.current.params.sittingId}}/{{$route.current.params.albumId}}/"
class="button back"><icon type="back"></icon></a>

<a data-ng-if="(routeService.get().name == 'ALBUM' || routeService.get().name == 'PREVIEW') && $route.current.params.pictureId && (routeService.get().fullName.toUpperCase().indexOf('FAVOURITES') > -1)"
data-ng-href="/Favourites/"
class="button back"><icon type="back"></icon></a>

<a data-ng-if="routeService.get().name == 'PREMIUM'"
data-ng-href="{{(routeService.getPreviousRoute().toUpperCase().indexOf('PREMIUM') < 0 ? routeService.getPreviousRoute() : '/MyAlbums/')}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'PACKS' && $route.current.params.productId"
data-ng-href="{{(routeService.getPreviousRoute().toUpperCase().indexOf('PACKS') < 0 ? routeService.getPreviousRoute() : '/Packs/')}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'PACK' && $route.current.params.productId"
data-ng-href="{{(routeService.getPreviousRoute().toUpperCase().indexOf('PACK/') < 0 ? routeService.getPreviousRoute() : '/Packs/')}}"
<a data-ng-if="routeService.get().name == 'WHOLE' && $route.current.params.productId"
data-ng-href="{{(routeService.getPreviousRoute().toUpperCase().indexOf('WHOLE') < 0 ? routeService.getPreviousRoute() : '/MyAlbums/')}}"
class="button back"><icon type="back"></icon></a>

<a data-ng-if="routeService.get().name == 'CHANGEPASSWORD'"
data-ng-href="/AccountDetails"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'CHECKOUT'"
data-ng-href="/Basket"
class="button back"><icon type="back"></icon></a>

<a data-ng-if="routeService.get().name == 'ACCOUNT'"
data-ng-href="/MyAlbums"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'ACCOUNTDETAILS'"
data-ng-href="{{routeService.getPreviousRoute().toUpperCase().indexOf('ACCOUNTDETAILS') < 0 ? routeService.getPreviousRoute() : '/Account'}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'ADDRESSES'"
data-ng-href="{{routeService.getPreviousRoute().toUpperCase().indexOf('ADDRESSES') < 0 ? routeService.getPreviousRoute() : '/Account'}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'ORDERS'"
data-ng-href="{{routeService.getPreviousRoute().toUpperCase().indexOf('ORDERS') < 0 ? routeService.getPreviousRoute() : '/Account'}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="$route.current.params.orderId"
data-ng-href="{{routeService.getPreviousRoute().toUpperCase().indexOf('ORDERS') < 0 ? '/MyAlbums' : '/Orders'}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'APPROVALS'"
data-ng-href="{{routeService.getPreviousRoute().toUpperCase().indexOf('APPROVALS') < 0 ? routeService.getPreviousRoute() : '/Account'}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'DOWNLOADS'"
data-ng-href="{{routeService.getPreviousRoute().toUpperCase().indexOf('DOWNLOADS') < 0 ? routeService.getPreviousRoute() : '/Account'}}"
class="button back"><icon type="back"></icon></a>

<a data-ng-if="routeService.get().name == 'BASKET'"
data-ng-href="{{routeService.getPreviousRoute().toUpperCase().indexOf('BASKET') < 0 ? routeService.getPreviousRoute() : '/MyAlbums'}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'LOGIN' && (routeService.getPreviousRoute() != null && routeService.getPreviousRoute().toUpperCase().indexOf('LOGIN') <= 0)"
data-ng-href="{{routeService.getPreviousRoute()}}"
class="button back"><icon type="back"></icon></a>
<a data-ng-if="routeService.get().name == 'LOGIN' && (routeService.getPreviousRoute() == null || routeService.getPreviousRoute().toUpperCase().indexOf('LOGIN') > 0)"
data-ng-click="back()"
class="button back"><icon type="back"></icon></a>

<div class="clear"></div>


