<load wait-for="loadingPage">
    <div ng-show="alert" class="alert"><p>{{alert}}</p></div>
    <a class="button" ng-show="model" data-ng-href="/ChangePassword">{{'CHANGEPASSWORD' | translate}}</a>
    <div class="form" ng-show="!isLoading">
        <br />
        <div class="fields" ng-show="model">
            <div class="form-group no-collapse">
                <label>{{'REGISTER_EMAIL' | translate}}&nbsp;<span class="alert">*</span></label>
                <input type="text" data-ng-model="model.email" />
            </div>
            <p class="alert" ng-show="fields[0].valid == false">{{'ERROR_EMAIL' | translate}}</p>
            <div class="form-group no-collapse">
                <label>{{'REGISTER_FORENAME' | translate}}&nbsp;<span class="alert">*</span></label>
                <input type="text" data-ng-model="model.forename" />
            </div>
            <p class="alert" ng-show="fields[1].valid == false">{{'ERROR_FORENAME' | translate}}</p>
            <div class="form-group no-collapse">
                <label>{{'REGISTER_SURNAME' | translate}}&nbsp;<span class="alert">*</span></label>
                <input type="text" data-ng-model="model.surname" />
            </div>
            <p class="alert" ng-show="fields[2].valid == false">{{'ERROR_SURNAME' | translate}}</p>
            <div class="form-group no-collapse">
                <label>{{'REGISTER_TELEPHONENUMBER' | translate}}</label>
                <input type="text" data-ng-model="model.telephoneNumber" />
            </div>
            <div class="form-group no-collapse">
                <label>{{'REGISTER_COUNTRY' | translate}}&nbsp;<span class="alert">*</span></label>
                <select id="countrySelect" data-ng-model="model.countryId"
                        data-ng-options="country.id as country.name for country in countries"></select>
            </div>
            <p class="alert" ng-show="fields[3].valid == false">{{'ERROR_COUNTRY' | translate}}</p>

            <br></br>
            <div id="privacy" class="form-group">
                <input class="col-12 margin-right-15 class-input no-mouseflow" type="checkbox" data-ng-model="model.optOutMarketing" />
                <p class="col-12" ng-bind-html="'PRIVACY_OPTOUT' | translate"></p>
            
                <div id="privacy-more-trigger" ng-click="showMore = !showMore;">
                    <h3 class="col-12">{{'PRIVACY_HEADER' | translate}}
                        <span class="fa fa-chevron-down" ng-if="! showMore"></span>
                        <span class="fa fa-chevron-up" ng-if="showMore"></span>
                    </h3>
                </div>
                
                <div class="animate fade" ng-show="showMore" id="privacy-more">
                    <p ng-bind-html="'PRIVACY_INFO' | translate"></p>
                    <p ng-bind-html="'PRIVACY_MARKETING' | translate"></p>
                    <p ng-bind-html="'PRIVACY_UNSUBSCRIBE' | translate"></p>
                </div>
            </div>
       </div>
        <br></br>
        <div class="form-group btns" id="submit">
            <a class="button green submit" ng-show="model" ng-disabled="loading" ng-click="submit(model)"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_SUBMIT' | translate}}</a>
        </div>
    </div>
</load>
