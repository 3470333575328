<div ng-show="alert" class="alert"><p>{{alert}}</p></div>

<div class="form" ng-show="!isLoading">
    <p class="padding-bottom-20" data-ng-show="!requestOldPassword">{{'ERROR_NEWPASSWORD' | translate}}</p>
    <div class="fields" ng-show="passwords">
        <div class="form-group no-collapse" data-ng-show="requestOldPassword">
            <label>{{'ACCOUNT_OLDPASSWORD' | translate}}&nbsp;<span class="alert">*</span></label>
            <input type="password" data-ng-model="passwords.oldPassword" class="required" />
        </div>
        <p class="alert" ng-show="requestOldPassword && fields[0].valid == false">{{'ERROR_OLDPASSWORD' | translate}}</p>
        <p class="alert" ng-show="!oldPasswordCorrect">{{'ERROR_INCORRECT_OLD_PASSWORD' | translate}}&nbsp;<a class="classic-link" ng-click="showResetForm()">{{'FORGOT_PASSWORD' | translate}}</a></p>
        <p class="alert" ng-show="requestDenied" ng-bind-html="errorMessage"></p>
        <div class="form-group no-collapse">
            <label>{{'NEW_PASSWORD' | translate}}&nbsp;<span class="alert">*</span></label>
            <input type="password" data-ng-model="passwords.newPassword" class="required" />
        </div>
        <p class="alert" ng-show="fields[1].valid == false">{{'ERROR_NEWPASSWORD' | translate}}</p>
        <div class="form-group no-collapse">
            <label>{{'CONFIRM_NEW_PASSWORD' | translate}}&nbsp;<span class="alert">*</span></label>
            <input type="password" data-ng-model="passwords.confirmPassword" class="required" />
        </div>
        <p class="alert" ng-show="fields[2].valid == false">{{'ERROR_CONFIRMPASSWORD' | translate}}</p>
        <p class="alert" ng-show="!passwordsMatch">{{'UNMATCHED_PASSWORDS' | translate}}</p>
    </div>
    <div class="form-group btns" id="submit">
        <a class="button green submit" ng-show="passwords" ng-disabled="loading" ng-click="changePassword(passwords)"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_SUBMIT' | translate}}</a>
    </div>

    <div ng-show="showReset">
        <br />
        <div class="form-group">
            <label>{{'REGISTER_EMAIL' | translate}}</label>
            <input type="text" data-ng-model="form.email" />
            <p class="alert" ng-show="invalidEmail">{{'ERROR_EMAIL' | translate}}</p>
            <p class="alert" ng-show="userNotFound">{{'ERROR_USER_NOT_FOUND' | translate}}</p>
            <a class="button" ng-disabled="loading" ng-click="resetPassword()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'SEND_BUTTON' | translate }}</a>
        </div>

        <p>{{'RESET_PASSWORD_EXPLANATION' | translate }}</p>
    </div>
</div>
