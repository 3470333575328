<div class="tab-bar">

    <ul class="button-list">
        <li ng-repeat="tab in tabs" ng-hide="tab.hide || hideAllTabs">
            <button ng-class="{ 'active' : selectedIndex == $index }" ng-click="selectTab($index)">{{tab.label | translate}}</button>
        </li>
    </ul>

    <!-- We need a way of optionally displaying each tab in the window. -->
    <ng-transclude></ng-transclude>
</div>