

<div data-ng-if="loading" class="col-12 loading-indicator fade"><i class="fa fa-spinner fa-spin"></i></div>

<preference-modal preference-data="statStore.marketing.prefs" ng-if="showPreferencesReminder()"></preference-modal>

<div class="col-12" data-ng-if="! loading">
	<div fix-and-scroll offset="200" class="col-12 content-wrapper-grey">
		<!--<h1 class="margin-bottom-20">{{'FAVOURITES_TITLE' | translate: { amountOfFavourites: favouritesCount } }}</h1>-->
		<div class="col-12">
			<div data-ng-repeat="(key, year) in sitting.favourites.images | orderObjectBy:key:true">
				<h1 class="event-year" style="padding-bottom: 15px;">{{year[0].eventYear}}</h1>
				<div class="album-images" data-ng-repeat="image in year">
					<div class="album-image" data-ng-click="routeService.setPreviousRoute('/Favourites');navService.previewFavourite(image.id)" data-ng-style="{'background-image': 'url(' + image.preview.url + ')'}">
						<favourite image="image"></favourite>
						<a class="button image-icons-buy"><strong>Buy</strong></a>
						<div class="image-icons">
							<!-- <icon data-ng-show="image.isFavourite" data-ng-click="togglePictureFavouriteState(image); $event.stopPropagation();" class="icon-heart full" type="heart"></icon>
							<icon data-ng-hide="image.isFavourite" data-ng-click="togglePictureFavouriteState(image); $event.stopPropagation();" class="icon-heart" type="heart-empty"></icon> -->
							<div data-ng-click="goToBasket($event)" ng-if="image.basketCount && image.basketCount >0" class="image-basket-count-favourite icon"><i class="fa fa-shopping-basket"></i><span>{{image.basketCount}}</span></div>
						</div>
					</div>

					<p class="image-name">{{image.imgFileName}}</p>

				</div>
			</div>
			<div data-ng-if="noFavourites()">
				<br><p>{{'NO_FAVOURITES_MESSAGE' | translate}}</p>
			</div>
			<!--<div class="album-images" data-ng-repeat="image in sitting.favourites.images">
				<div class="album-image" data-ng-click="routeService.setPreviousRoute('/Favourites');navService.previewPicture(image.id)" data-ng-style="{'background-image': 'url(' + image.preview.url + ')'}">
					<div class="image-icons">
						<icon data-ng-show="image.isFavourite" data-ng-click="togglePictureFavouriteState(image); $event.stopPropagation();" class="icon-heart full" type="heart"></icon>
						<icon data-ng-hide="image.isFavourite" data-ng-click="togglePictureFavouriteState(image); $event.stopPropagation();" class="icon-heart" type="heart-empty"></icon>
						<div ng-if="image.basketCount && image.basketCount >0" class="image-basket-count-favourite icon"><i class="fa fa-shopping-basket"></i><span>{{image.basketCount}}</span></div>
					</div>
				</div>

				<p class="image-name">{{image.imgFileName}}</p>

			</div>-->
		</div>
	</div>
</div>
