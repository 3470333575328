When you create a new modal, you need to do the following:
- Create a new HTML template in assets/html/directives/modal/modalNAME.html
- Create a new JavaScript controller for the new modal in assets/js/directives/modal/modalNAME.js
- Add a new modalType to the app.constants.js file that you will use to determine your modal
- Add your new modal directive, eg: <modal-picture-picker data-ng-if="modalBase.type == modalTypes.picturePicker"></modal-picture-picker> to the modalCore.html
  inside the <div class="modal-scroll"></div> section.

Now that the modal is created, you need to add it to the area you're going to be using modals.
- In the HTML of the file you're going to call the modal from, just add <modal></modal> somewhere in the HTML.
- Inject the 'modalTypes' dependency in the controller
- Where you want to call the modal, simply call: modalBase.showModal(modalTypes.products, YOURDATA);