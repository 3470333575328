<!-- Let's change the way we render payment methods -->

<ul ng-if="paymentMethods" id="paymentMethods">
    <li ng-repeat="method in paymentMethods">
        <button ng-click="switchPayment(method.key)" ng-class="{'active': switchedPayment == method.key}">
            {{ 'PAYMENTMETHOD_' + method.key.toUpperCase() | translate }}
        </button>
    </li>
</ul>

<div id="paymentContainer">

    <!-- Card provider -->
    <div id="card-provider" class="provider" ng-if="switchedPayment == 'BraintreeCards'">
        <div id="card-wrapper">
            <form class="form" action="/" id="braintreeForm" method="post">

                <ul id="payment-cards-list">
                    <li ng-repeat="(key, value) in faCards">
                        <i class="fa {{key}}" ng-class="{'active': faCardBrand == key}" aria-hidden="true"></i>
                    </li>
                </ul>

                <div class="form-group">
                   <label for="card-number" class="class-label">{{ 'PAYMENT_NAME' | translate }}
                   </label>
                    <input required name="card-name" class="class-input" type="text" ng-model="paymentData.brainTreeCards.cardName" ng-focus="focusSlide()" />
                </div>

                <div class="form-group">
                    <label for="card-number" class="class-label">{{ 'PAYMENT_CARDNUMBER' | translate }}
                   </label>
                    <div id="card-number" class="class-input hosted-field"></div>
                </div>

                <div class="form-group">
                    <label for="cvv" class="class-label">{{ 'PAYMENT_CVV' | translate }}</label>
                    <div id="cvv" class="class-input hosted-field"></div>
                </div>

                <div class="form-group">
                    <label for="expiration-date" class="class-label">{{ 'PAYMENT_EXPIRATION' | translate }}</label>
                    <div id="expiration-date" class="class-input hosted-field"></div>
                </div>
            </form>
        </div>
    </div>

    <!-- Static Wrapper -->
    <div id="static-provider" class="provider" ng-if="isStaticPayment">
        <div id="static-wrapper">

            <div ng-bind-html="'PAYMENT_'+switchedPayment.toUpperCase() | translate"></div>

       </div>
    </div>

    <div id="validation" ng-show="! validate()">
        <p class="alert">{{ 'PAYMENT_VALIDATION_WARNING' | translate }}</p>
    </div>

    <p ng-if="errors" class="alert">
        {{errors}}
    </p>

    <div class="margin-top-20 text-align-center">
        <button id="submit-button" class="button green pay" ng-if="payButtonVisible" ng-disabled="(! validate()) || (! paymentEnabled) || (! paymentValid)" ng-click="pay(paymentData)">Pay<span ng-if="isLoading"> <i class="fa fa-spinner fa-spin"></i></span>
        </button>
    </div>

    <!-- Paypal -->
    <div id="paypal-provider" class="provider" ng-if="switchedPayment == 'BraintreePaypal'">
            <div id="paypal-wrapper">

                <div ng-show="validate()">
                    <p class="margin-bottom-10">{{ 'PAYMENT_PAYPAL' | translate }}</p>

                    <div id="paypal-button" name="paypal-button">
                    </div>
                </div>
           </div>
    </div>
</div>

