
<load wait-for="loadingPage">
    <div class="product content-wrapper-grey premium">
        <div class="product-info">
            <div class="logo" data-ng-style="{'background-image': 'url(' + getPreviewUrl() + ')'}" ></div>
        </div>
        <div class="product-details">
            <h2 class="product-title">{{itemStore.publicProductItem.productName}}</h2>
            -
            <p class="product-price" ng-if="itemStore.publicProductItem.price == null">Build Product to get Price</p>
            <p class="product-price" ng-if="itemStore.publicProductItem.discountValue == null && itemStore.publicProductItem.price != null"><span class="from"></span> {{itemStore.publicProductItem.finalPrice}}</p>
            <p class="product-price" ng-if="itemStore.publicProductItem.discountValue !== null && itemStore.publicProductItem.price != null">
                <span class="from"></span>
                <span class="strikethrough">{{itemStore.publicProductItem.price}}</span> <span class="alert">{{itemStore.publicProductItem.finalPrice}}</span>
            </p>
            <div class="product-brief" data-ng-bind-html="itemStore.publicProductItem.shortDescription"></div>
            <div class="product-brief" ng-show="toggleDescription" data-ng-bind-html="itemStore.publicProductItem.longDescription"></div>
            <a class="classic-link product-details small-font" data-ng-click="toggleLongDescription()" data-ng-if="itemStore.publicProductItem.longDescription != null">...{{ toggleDescriptionLabel | translate }}</a>
        </div>

        <div class="product-details-and-options">
            <div class="product-option" data-ng-repeat="option in itemStore.publicProductItem.options">
                <label>{{option.displayName}}</label>
                <!--
                    We have `data-ng-if="!optionItem"` on our default option so that it is not available once an option has been selected
                -->
                <select data-ng-init="optionItem = setupOptions(option)"
                        data-ng-options="optionItem.description for optionItem in option.items"
                        data-ng-model="optionItem"
                        data-ng-change="updateProductOptions(itemStore.publicProductItem, option, optionItem)"
						data-ng-disabled="disableOptions">
                    <option data-ng-if="!optionItem" value="">
                        {{ 'PLEASE_SELECT' | translate }}
                    </option>
                </select>
                <i class="fa option-icon" data-ng-class="{
				   'fa-check green': option.completed && !option.loading,
				   'fa-close red': !option.completed && !option.loading,
				   'fa-spinner fa-spin blue': option.loading
				   }"></i>
            </div>
        </div>
        <div class="clear"></div>
        <hr />
        <div class="picture-chooser-wrapper">
            <div data-ng-repeat="image in itemStore.publicProductItem.images" class="picture-container">
                <!-- MATT this doesnt apply for some reason ng-style="{height:{{200*image.ratio}}px" i added this below -->
                <div class="child-product-wrapper-premium">
                    <div data-ng-style="{'background-image':'url(' + image.previewUrl + ')'}" class="picture-preview" ng-style="{height:{{200*image.ratio}}px"><span data-ng-if="image.isPlaceholder">{{image.description}}</span></div>

                    <button class="button" ng-disabled="itemStore.publicProductItem.showSpinner || loading" data-ng-click="startPicker(image)"><i ng-show="itemStore.publicProductItem.showSpinner || loading" class="fa fa-spinner fa-spin"></i>&nbsp;{{ 'BUTTON_CHOOSE_SITTING' | translate}}</button>

                    <p ng-if="itemStore.publicProductItem.sittingPassword"><span class="bold">{{'SITTINGID' | translate}}</span> {{itemStore.publicProductItem.sittingPassword}}</p>

                </div>

            </div>
            <p class="product-price premium-price" ng-if="itemStore.publicProductItem.discountValue == null && itemStore.publicProductItem.price != null">{{ 'TOTAL' | translate }}: {{itemStore.publicProductItem.finalPrice}}</p>
            <p class="product-price premium-price" ng-if="itemStore.publicProductItem.discountValue !== null && itemStore.publicProductItem.price != null">
                {{ 'TOTAL' | translate }}:
                <span class="strikethrough">{{itemStore.publicProductItem.price}}</span> <span class="alert">{{itemStore.publicProductItem.finalPrice}}</span>
            </p>
            <a class="button cancel arial" data-ng-href="{{(routeService.getPreviousRoute().toUpperCase().indexOf('WHOLE') < 0 ? routeService.getPreviousRoute() : '/MyAlbums/')}}">{{ 'BUTTON_CANCEL' | translate }}</a>
            <button data-ng-click="addToBasket(itemStore.publicProductItem)" data-ng-disabled="disableBuy(itemStore.publicProductItem) || loading" class="button green buy-action arial"><span data-ng-if="itemStore.publicProductItem.showSpinner || loading"><i class="fa fa-spinner fa-spin"></i> </span>{{ 'BUTTON_ADD_TO_BASKET' | translate: { RES: itemStore.publicProductItem.imgQuantity } }}</button>
        </div>
    </div>
</load>