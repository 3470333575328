<!-- This defines the root of our modal. If $rootScope.showingModal is true, we show the div. -->
<div data-ng-if="$root.showingModal" class="modal-root">
    <div class="modal">
        <!--
            This is our generic modal header. It will contain everything required by all modals.
            TODO:
                - Implement generic 'continue' button that allows the user to pass a function.
                - Implement generic header text.
        -->
        <div class="modal-header">
            <a data-ng-click="modalBase.backAction()"
               data-ng-disabled="modalBase.disableBackAction()"
               class="button cancel-button close"><icon data-ng-if="!modalBase.backIcon" type="arrow"></icon><i data-ng-if="modalBase.backIcon" class="fa" data-ng-class="modalBase.backIcon"></i><span class="desktop-show" data-ng-if="modalBase.backMessageKey != null"> {{modalBase.backMessageKey | translate}}</span></a>
            <!--<a data-ng-click="modalBase.closeModal()"
               class="button cancel-button close"><i class="fa fa-close"></i> {{'BUTTON_CLOSE' | translate}}</a>-->
            <!-- Using the ng-bind-html, it allows us to render HTML in the header dynamically -->
            <h1 data-ng-bind="modalBase.headerTitle"
                data-ng-if="! modalBase.isMobile"
                 class="modal-header-element"></h1>
			<a data-ng-if="modalBase.showContinueAction()"
					data-ng-disabled="modalBase.disableContinueAction()"
					data-ng-click="modalBase.continueAction()"
					class="button green continue">
				<i data-ng-show="modalBase.showContinueSpinner()" class="fa fa-spin fa-spinner"></i> {{'BUTTON_ADD_TO_BASKET' | translate}}
			</a>
        </div>
        <!-- This defines the content section of our modal. -->
        <div class="modal-content">

            <h1 data-ng-bind="modalBase.headerTitle"
                data-ng-if="modalBase.isMobile"
                 class="modal-header-element"></h1>

            <!-- Defines the scrollable section of the modal. -->
            <div class="modal-scroll">
                <!-- For each child modal we implement, they must be contained here with a 'data-ng-if' to show the correct one. -->
                <modal-premium data-ng-if="modalBase.type == modalTypes.premium"></modal-premium>
                <modal-picture-picker data-ng-if="modalBase.type == modalTypes.picturePicker"></modal-picture-picker>
                <modal-products data-ng-if="modalBase.type == modalTypes.products"></modal-products>
                <modal-whole data-ng-if="modalBase.type == modalTypes.whole"></modal-whole>
                <modal-confirm-crop data-ng-if="modalBase.type == modalTypes.confirmCrop"></modal-confirm-crop>
                <modal-pack data-ng-if="modalBase.type == modalTypes.pack"></modal-pack>
                <modal-pack-reminder data-ng-if="modalBase.type == modalTypes.packReminder"></modal-pack-reminder>
                <modal-login-reminder data-ng-if="modalBase.type == modalTypes.loginReminder"></modal-login-reminder>
            </div>
        </div>
    </div>
</div>