<d-retention-modal sitting-data="sitting" prop="dataRetentionEventIds"
    ng-if="sitting.dataRetentionEventIds && sitting.dataRetentionEventIds.length">
</d-retention-modal>

<preference-modal preference-data="statStore.marketing.prefs" ng-if="showPreferencesReminder()"></preference-modal>

<div data-ng-if="loading" class="col-12 loading-indicator fade"><i class="fa fa-spinner fa-spin"></i></div>

<div class="col-12" ng-if="! loading">

        <div fix-and-scroll offset="200" class="content-wrapper-grey">
            <div class="col-12" style="white-space: collapse;">

                <span style="color: #F3F3F3">{{setFocus()}}</span>

                <!-- I want to repeat the elements, but every third,
                   interject album-product.  -->
                <!-- Do not move album-product.  Adding line breaks causes whitespace, which breaks mobile layout. -->

                <div class="album-images" data-ng-repeat-start="image in sitting.none.images">
                    <div
                        tabindex="0" id="{{image.id}}" class="album-image" data-ng-click="previewPicture(image)"
                        data-ng-style="{'background-image': 'url(' + image.preview.url + ')'}">
                        <div data-ng-click="goToBasket($event)" ng-if="image.basketCount && image.basketCount >0"
                            class="image-basket-count"><i
                                class="fa fa-shopping-basket"></i><span>{{image.basketCount}}</span></div>
                        <favourite image="image"></favourite>
                        <a class="button image-icons-buy"><strong>{{ 'BUY' | translate }}</strong></a>
                    </div>
					<p class="image-name">{{image.imgFileName}}</p>
                </div><album-product data-ng-repeat-end ng-if="((($index - 1) % 2) == 0) && (($index-1)/2)&lt;promotedProducts.length && promotedProducts.length > 0" product="promotedProducts[($index-1)/2]" sitting-data="sitting"></album-product><album-product ng-repeat="product in remainingProducts" product="product"></album-product><div class="upsells" data-ng-class="{ open: upsellOpen }" data-ng-if="sitting.upsells.length != 0">
                    <h3 ng-click="toggleUpsell()">
                        <span id="upsellTimer">{{ 'DISCOUNTS' | translate }}</span>
                        <span class="fa fa-caret-up" ng-if="! upsellOpen"></span>
                        <span class="fa fa-caret-down" ng-if="upsellOpen"></span>
                    </h3>
                    <ul>
                        <li data-ng-repeat="upsell in getUpsells()">
                            <p>{{upsell.description}}<span ng-if="upsell.validUntil != null"> until {{upsell.validUntil | moment:'Do MMMM YYYY'}}</span></p>
                        </li>
                    </ul>
                </div>
        </div>
    </div>
</div>
