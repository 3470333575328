<div class="modal">
    <div class="modal_container">
        <h3>{{'SELECT_PREFERENCES' | translate}}</h3>

        <ul>
            <li ng-repeat="preference in preferenceDto">
                <label>
                    <input type="checkbox" class="margin-reset" ng-value="preference.value" ng-model="preference.value"/>
                    <span data-ng-bind-html="preference.string | translate"></span>
                </label>
                <span style="padding-left: 30px;"><i class="button-icon fa fa-question-circle" ng-click="toggleCallout(preference)"></i></span>
                <div style="padding-top: 10px; text-indent: initial;" class="preference-callout" ng-show="preference.showCallout" data-ng-bind-html="preference.calloutString | translate"></div>
            </li>
        </ul>

        <div class="text-center">
            <button class="button green" ng-click="continue()">
                <i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>{{'CONTINUE' | translate }}
            </button>
        </div>
    </div>
</div>