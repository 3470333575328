<div data-ng-repeat="(key, folder) in setupSittingData.none.folders.years" class="album-folders">
	<h1 class="event-year">{{key}}</h1>
	<div data-ng-repeat="(key, sitting) in folder">
		<h2 class="sitting-title">{{key}}</h2>
		<div data-ng-repeat="(key, album) in sitting" class="album-folders">
			<div data-ng-click="navService.album(sittingId, album.id)" class="content-wrapper-grey event-folder">
				<div class="album-info">
					<div data-ng-style="{'background-image':'url({{album.preview.url}})'}" class="album-thumb album-image"></div>
					<div class="album-details">
						<h2 class="album-title">{{album.displayName}}</h2>
						<p class="album-description">
                            {{ album.organisationName }}<br />
                            {{ getPrettyEventDate(album.eventDate) }}<br />
                            {{'SITTINGID' | translate}}: {{ album.sittingPassword }}
                        </p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>