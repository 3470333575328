<cookie-message></cookie-message>
<br />
<br />


<div id="notAvailable" ng-if="! weChatPayAvailable">

    <h2>WeChat Pay Is Not Available on your platform.</h2>

    <p>Please visit the shop in the WeChat Browser.</p>
</div>

<!-- Show we chat pay status -->
<div id="available" ng-if="weChatPayAvailable">
    <span>{{weChatPayStatus}}</span>
</div>



