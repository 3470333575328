<div ng-hide="loadingPage">
    <h1 ng-if="!validated.email">{{'REGISTER' | translate}}</h1>
    <h1 ng-if="validated.email">{{'COMPLETE_REGISTER' | translate}}</h1>

    <div ng-bind-html="'REGISTER_BLURB' | translate"></div>

    <div class="">
        <div class="">
            <label class="class-label sr-only">{{'REGISTER_EMAIL' | translate}}&nbsp;<span class="alert">*</span></label>
			<input class="class-input no-mouseflow" type="text" placeholder="{{'REGISTER_EMAIL' | translate}}" ng-blur="validateFieldIndex(3)" data-ng-model="model.email" data-ng-disabled="validated.email" ng-keyup="$event.keyCode == 13 && register()" />
        </div>
        <p class="alert" ng-show="userFields[3].valid == false">{{'ERROR_EMAIL' | translate}}</p>
   </div>
    <p class="alert" ng-show="userExists == 1" data-ng-bind-html="'USER_ALREADY_EXISTS' | translate"></p>
    <p class="alert" ng-show="userExists == 2" data-ng-bind-html="'USER_ALREADY_EXISTS_REMOTE' | translate"></p>

    <div class="">
        <div class="">
			<select id="registerCountrySelect" class="no-mouseflow" data-ng-model="model.country"
					data-ng-options="country.name for country in countries"></select>
        </div>
        <p class="alert" ng-show="userFields[2].valid == false">{{'ERROR_COUNTRY' | translate}}</p>
       <div class="no-collapse">
            <label class="class-label sr-only">{{'REGISTER_FORENAME' | translate}}&nbsp;<span class="alert">*</span></label>
			<input class="class-input no-mouseflow" type="text" data-ng-model="model.forename" ng-blur="validateFieldIndex(0)" ng-keyup="$event.keyCode == 13 && register()" placeholder="{{'REGISTER_FORENAME' | translate}}" />
        </div>
       <div class="no-collapse">
            <label class="class-label sr-only">{{'REGISTER_SURNAME' | translate}}&nbsp;<span class="alert">*</span></label>
			<input class="class-input no-mouseflow" type="text" data-ng-model="model.surname" ng-blur="validateFieldIndex(1)" ng-keyup="$event.keyCode == 13 && register()" placeholder="{{'REGISTER_SURNAME' | translate}}" />
        </div>
        <p class="alert" ng-show="userFields[0].valid == false">{{'ERROR_FORENAME' | translate}}</p>
        <p class="alert" ng-show="userFields[1].valid == false">{{'ERROR_SURNAME' | translate}}</p>
    </div>

   <div class="">
        <div class="password-container">

            <label class="class-label sr-only">{{'LOGIN_PASSWORD' | translate}}&nbsp;<span class="alert">*</span></label>
            <div class="input-group double class-input">
                <input ng-if="! showPassword" class="no-mouseflow" placeholder="{{'LOGIN_PASSWORD' | translate}}" ng-blur="validateFieldIndex(4);" ng-change="validatePasswordStrength();" type="password" data-ng-model="$parent.model.password" ng-keyup="$event.keyCode == 13 && login()" /><span ng-if="! showPassword" ng-click="changeShowPassword(true, 'showPassword');" class="fa fa-eye"></span>
                <input ng-if="showPassword" class="no-mouseflow" placeholder="{{'LOGIN_PASSWORD' | translate}}" ng-blur="validateFieldIndex(4);" ng-change="validatePasswordStrength();" type="text" data-ng-model="$parent.model.password" ng-keyup="$event.keyCode == 13 && login()" /><span ng-if="showPassword" ng-click="changeShowPassword(false, 'showPassword');" class="fa fa-eye-slash"></span>

                <span ng-if="passwordStrength==1" class="fa fa-thermometer-empty red" aria-hidden="true"></span>
                <span ng-if="passwordStrength==2" class="fa fa-thermometer-half yellow" aria-hidden="true"></span>
                <span ng-if="passwordStrength==3" class="fa fa-thermometer-full green" aria-hidden="true"></span>
            </div>
       </div>
       <div class="password-container">
        <p class="alert" ng-show="userFields[4].valid == false">{{'ERROR_NEWPASSWORD' | translate}}</p>
        <div ng-if="model.password" class="" style="text-align: left;">
            <label class="class-label sr-only">{{'LOGIN_CONFIRMPASSWORD' | translate}}&nbsp;<span class="alert">*</span></label>
            <div class="input-group double class-input">
                <input ng-if="! $parent.showConfirmPassword" class="no-mouseflow" placeholder="{{'LOGIN_CONFIRMPASSWORD' | translate}}" ng-blur="validateFieldIndex(5);passwordsMatch();" type="password" data-ng-model="$parent.model.confirmPassword" ng-keyup="$event.keyCode == 13 && login()" /><span ng-if="! $parent.showConfirmPassword" ng-click="$parent.changeShowPassword(true, 'showConfirmPassword');" class="fa fa-eye"></span>
                <input ng-if="$parent.showConfirmPassword" class="no-mouseflow" placeholder="{{'LOGIN_CONFIRMPASSWORD' | translate}}" ng-blur="validateFieldIndex(5);passwordsMatch();" type="text" data-ng-model="$parent.model.confirmPassword" ng-keyup="$event.keyCode == 13 && login()" /><span ng-if="$parent.showConfirmPassword" ng-click="$parent.changeShowPassword(false, 'showConfirmPassword');" class="fa fa-eye-slash"></span>
            </div>
        </div>
        </div>
        <p class="alert" ng-show="userFields[5].valid == false">{{'ERROR_CONFIRMPASSWORD' | translate}}</p>
        <p class="alert" ng-show="! validPasswordsMatch">{{'UNMATCHED_PASSWORDS' | translate}}</p>

        <div id="privacy" class="form-group" ng-if="!validated.isValidated">
            <h3>{{'PRIVACY_HEADER' | translate}} <a href id="privacy-more-trigger" ng-click="showMore = !showMore;" >
                    <span ng-if="! showMore">{{'SHOW_MORE' | translate}}</span>
                    <span ng-if="showMore">{{'SHOW_LESS' | translate}}</span>
            </a> </h3>

                <div class="animate fade" ng-show="showMore" id="privacy-more">
                    <p ng-bind-html="'PRIVACY_INFO' | translate" style="max-width: 100% !important;"></p>
                    <p ng-bind-html="'PRIVACY_MARKETING' | translate" style="max-width: 100% !important;"></p>
                    <p ng-bind-html="'PRIVACY_UNSUBSCRIBE' | translate" style="max-width: 100% !important;"></p>
                </div>

                <div>
                    <input style="float:right;" class="class-input no-mouseflow" type="checkbox" data-ng-model="model.notifyMe" />
                    <p class="register">{{'NOTIFYME_PICTURES' | translate}}&nbsp;<i class="button-icon fa fa-question-circle" ng-click="toggleNotifyMeCallout()"></i></p>
                </div>
                <p class="preference-callout" style="max-width: 100%;" ng-show="showNotifyMeCallout">{{'NOTIFYME_PICTURES_CALLOUT' | translate}}</p>

                <div>
                    <input style="float:right;" class="class-input no-mouseflow" type="checkbox" data-ng-model="model.optOutMarketing" />
                    <p class="register">{{'PRIVACY_OPTOUT' | translate}}&nbsp;<i class="button-icon fa fa-question-circle" ng-click="toggleOptOutCallout()"></i></p>
                </div>
                <div class="preference-callout" ng-bind-html="'PRIVACY_OPTOUT_CALLOUT' | translate" ng-show="showOptOutCallout"></div>
        </div>

        <div id="copyright" class="form-group small" ng-if="!validated.isValidated">
            <h3>{{'COPYRIGHT_HEADER' | translate}}</h3>

            <p class="register" ng-bind-html="'COPYRIGHT_INFO' | translate"></p>
        </div>


        </div>
        <div id="privacy">
        </div>
        <div>
            <button class="button" ng-click="register()" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{getRegisterButtonString() | translate}}</button>
        </div>
        <div class="bypass-register-container">
            <button id="bypass-register" class="button" data-ng-click="bypassRegister()" ng-disabled="loading" ng-if="showRegisterBypass()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'VIEW_PICTURES_WITHOUT_REGISTRATION' | translate}}</button>
        </div>
    </div>
</div>

<div ng-if="!validated.isValidated">
    <h5 class="margin-top-20">{{'LOGIN_HINT' | translate}}
    <a class="" href="/login">{{'LOGIN' | translate}}</a></h5>
</div>
