<div class="content-wrapper-grey margin-bottom-20 basket-item">
    <div class="basket-pip">
        <img data-ng-src="{{item.pip.url || item.item.productThumbnailUrl}}" />
    </div>

    <div class="basket-details">
        <h2 class="product-title">
            {{item.name}} -
            <p class="product-price" ng-if="item.item.discountValue == null"> {{item.item.finalPrice}}</p>
            <p class="product-price" ng-if="item.item.discountValue !== null"><span class="strikethrough">{{item.item.price}}</span> <span class="alert">{{item.item.finalPrice}}</span> </p>
        </h2>
        <div class="product-options">
            <p data-ng-repeat="option in item.options">{{option.description}}</p>
        </div>

        <div class="basket-quantity-changer">
            <div class="basket-quantity-amt">
                <a ng-if="item.quantity > 1" data-ng-click="decrementQuantity(item)" class="button" ng-disabled="disableQuantityChange"><i class="fa fa-minus" style="padding: 0 !important; vertical-align: top !important;"></i></a>
                <a ng-if="item.quantity <= 1" data-ng-click="toggleAreYouSure()" class="button" ng-disabled="disableQuantityChange"><i class="fa fa-trash" style="padding: 0 !important; vertical-align: top !important;"></i></a>
                <input type="text" class="basket-quantity-input" ng-model="item.quantity" ng-change="typeInQuantity(item, item.quantity, $index)" />
                <a data-ng-click="incrementQuantity(item)" class="button" ng-disabled="disableQuantityChange"><i class="fa fa-plus"></i></a>
            </div>
        </div>
		<button data-ng-if="!showAreYouSure" data-ng-click="toggleAreYouSure()" class="remove-icon danger"><i class="fa fa-close"></i> {{'REMOVE' | translate}}</button>
		<are-you-sure callback="removeItemFromBasket()" condition="showAreYouSure" message="{{'ARE_YOU_SURE'}}"></are-you-sure>

    </div>

    <div class="basket-pictures">
        <br />

        <p class="crop-instructions" ng-data-instructions="{{item.customerInstructions}}" ng-if="item.cropping && item.customerInstructions && ! item.childItems.length"><span>{{ item.customerInstructions | translate }}</span></p>

        <a data-ng-click="toggleAdjustPicture()" ng-if="item.cropping && ! item.adjustPicture && ! item.childItems.length">{{ 'ADJUST_IMAGES' | translate}} <i class="fa fa-chevron-down"></i></a>
        <a data-ng-click="toggleAdjustPicture()" ng-if="item.cropping && item.adjustPicture && ! item.childItems.length">{{ 'HIDE_FILTERS' | translate }} <i class="fa fa-chevron-up"></i></a>
        <br /><br />
        <div class="pictures-list animate">
        </div>

        <div data-ng-repeat="image in item.item.images" class="basket-item-image-section" ng-if="item.cropping && item.adjustPicture === true">
            <img id="image" class="basket-item-image" data-ng-src="{{image.previewUrl}}" />
            <div class="adjust-buttons">
                <div class="button-group">
                    <crop item="item" image="image"></crop>
                    <a class="filter-preview button" ng-hide="image.previewWidth === image.previewHeight" ng-click="applyRotation(image.position)" ng-if="! item.lockRotation">
                        <i ng-show="rotateLoading(item.itemId)" class="fa fa-spin fa-spinner"></i>
                        <i ng-hide="rotateLoading(item.itemId)" class="fa fa-repeat"></i>&nbsp;
                        <span>{{ 'CHANGE_ORIENTATION' | translate }}</span>
                    </a>
                </div>
            </div>
        </div>
        <div ng-if="item.specialInstructions && ! item.childItems.length">
            <textarea class="note" ng-class="{required: checkNoteRequired()}" rows="6" ng-model="item.itemNotes" ng-blur="editNote(item.itemId, item.itemNotes)" placeholder="{{ 'NOTE_LABEL' | translate }}"/>&nbsp;<i ng-show="loading" class="fa fa-spin fa-spinner vertical-align-top"></i>
            <i id="{{item.itemId}}" class="green vertical-align-top"></i>
            <div ng-if="error">{{ 'ERROR_GENERIC' | translate}}</div>
        </div>

        <a data-ng-click="toggleChildItems()" ng-if="!showChildItems && item.childItems.length">{{ 'SHOW_BASKET_ITEMS' | translate}} <i class="fa fa-chevron-down"></i></a>
        <a data-ng-click="toggleChildItems()" ng-if="showChildItems && item.childItems.length">{{ 'HIDE_BASKET_ITEMS' | translate }} <i class="fa fa-chevron-up"></i></a>
        <br /><br />

        <div data-ng-repeat="childItem in item.childItems" ng-if="showChildItems">
            <div class="basket-pip-child">
                <img data-ng-src="{{childItem.pip.url}}" />
            </div>
            <div class="basket-details">
                <p class="product-title">
                    {{childItem.name}} -
                </p>
                <div class="product-options">
                    <p data-ng-repeat="option in childItem.options">{{option.description}}</p>
                </div>
            </div>

            <div>
                <a data-ng-click="toggleAdjustPicture(childItem)" ng-if="childItem.cropping && ! childItem.adjustPicture">{{ 'ADJUST_IMAGES' | translate}} <i class="fa fa-chevron-down"></i></a>
                <a data-ng-click="toggleAdjustPicture(childItem)" ng-if="childItem.cropping && childItem.adjustPicture">{{ 'HIDE_FILTERS' | translate }} <i class="fa fa-chevron-up"></i></a>
            </div>

            <br /><br />
            <div class="pictures-list animate">
            </div>

            <div data-ng-repeat="image in childItem.item.images" class="basket-item-image-section" ng-if="childItem.cropping && childItem.adjustPicture === true">
                <img id="image" class="basket-item-image" data-ng-src="{{image.previewUrl}}" />
                <div class="adjust-buttons">
                    <div class="button-group">
                        <crop item="childItem" image="image"></crop>
                        <a class="filter-preview button" ng-hide="image.previewWidth === image.previewHeight" ng-click="applyRotation(image.position, childItem)" ng-if="! childItem.lockRotation">
                            <i ng-show="rotateLoading(childItem.itemId)" class="fa fa-spin fa-spinner"></i>
                            <i ng-hide="rotateLoading(childItem.itemId)" class="fa fa-repeat"></i>&nbsp;
                            <span>{{ 'CHANGE_ORIENTATION' | translate }}</span>
                        </a>
                    </div>
                </div>
            </div>
            <p class="crop-instructions" ng-data-instructions="{{childItem.customerInstructions}}" ng-if="childItem.cropping && childItem.customerInstructions"><span>{{ childItem.customerInstructions | translate }}</span></p>
            <div ng-if="childItem.specialInstructions">
                <textarea class="note" ng-class="{required: checkNoteRequired()}" rows="6" ng-model="childItem.itemNotes" ng-blur="editNote(childItem.itemId, childItem.itemNotes)" placeholder="{{ 'NOTE_LABEL' | translate }}"/>&nbsp;<i ng-show="loading" class="fa fa-spin fa-spinner vertical-align-top"></i>
                <i id="{{childItem.itemId}}" class="green vertical-align-top"></i>
                <div ng-if="error">{{ 'ERROR_GENERIC' | translate}}</div>
            </div>
        </div>
    </div>
</div>