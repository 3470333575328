<d-retention-modal sitting-data="sitting" prop="dataRetentionEventIds" ng-if="sitting.dataRetentionEventIds && sitting.dataRetentionEventIds.length">
</d-retention-modal>

<load wait-for="preview">

<!-- It's at this point that we introduce the shop -->
<div class="col-7 preview">
	<div class="col-12 padding-top-20 preview">
		<div class="col-12">
			<div data-ng-swipe-left="changePicture(true)"
				 data-ng-swipe-right="changePicture(false)"
				 class="picture-preview">

					<a data-ng-click="changePicture(false)" class="arrow-left" ng-if="preview.id != preview.previous">
						<i class="fa fa-chevron-left"></i>
					</a>

					<div id="preview-img" data-ng-style="{'background-image': 'url(' + previewData.image.url + ')'}" class="album-image">
                    	<i class="fa fa-spinner fa-spin loading-preview" ng-show="loading"></i>
						<img ng-if="!showingModal"
							 id="zoom_01"
							 src="{{preview.lowRes.url}}"
							 data-zoom-image="{{preview.zoom.url}}"
							 data-ng-src="{{preview.lowRes.url}}" alt="{{preview.imgFileName}}"/>
						<favourite image="preview"></favourite>
                        <div data-ng-click="goToBasket($event)" ng-if="preview.basketCount && preview.basketCount >0" class="image-basket-count text-align-center">
                            <i class="fa fa-shopping-basket"></i><span>{{preview.basketCount}}</span>
                        </div>
						<div id="zoom-image"></div>
					</div>
					<a data-ng-click="changePicture(true)" class="arrow-right" data-ng-if="preview.id != preview.next">
						<i class="fa fa-chevron-right"></i>
					</a>
					<p class="image-name" style="font-size: .8em;">{{preview.imgFileName}}</p>

					<img id="preview-img-sticky" src="{{preview.lowRes.url}}" class="desktop-hide album-image"></img>
			</div>
		</div>
		<div class="lower-tab" id="scroll-notification" ng-if="showScrollNotification()" ng-click="scrollToProducts()">
			<p id="scroll-notification-text"><i class="fa fa-chevron-down"></i>  {{ 'HEADER_SHOP_BUTTON' | translate }}  <i class="fa fa-chevron-down"></i></p>
		</div>
	</div>
</div>


</load>