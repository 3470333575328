<div data-ng-class="{ 'open': product.showDetails == true }" data-ng-repeat="product in getProducts() track by $index" ng-click="toggleProductDetailsHandler(product, currentProductCategory)"
    id="product-{{product.id}}-{{currentProductCategory.id}}" class="product content-wrapper-grey animate">

    <div class="product-header">

        <div class="product-info" ng-class="{'product-info-basket-count' : product.basketCount  }">
            <div class="logo" data-ng-style="{'background-image':'url(' + product.thumbnailUrl + ')'}" ></div>
            <div data-ng-click="goToBasket($event)" ng-if="product.basketCount && product.basketCount >0" class="text-align-center image-basket-count-product icon"><i
                    class="fa fa-shopping-basket"></i><span>{{product.basketCount}}</span></div>
        </div>
        <div class="product-details" data-ng-class="{ 'small': isSingleImageProduct(product) }">
            <h2 class="product-title">{{product.name}}</h2>
            <p>
                <pre class="product-brief">{{ !product.showLongDescription ? product.shortDescription : product.description}}<br /><a class="product-details-show-more" data-ng-if="product.showDetails && !loadingOptions" data-ng-click="toggleLongDescription(product, $index); $event.stopPropagation();"><span class="classic-link">...{{(product.showLongDescription ? 'SHOW_LESS' : 'SHOW_MORE') | translate}}</span>
                </a></pre>
            </p>
        </div>
    </div>

    <div class="product-detail-block" ng-if="!bypassOptions(product) && product.showDetails">

        <div>
       </div>

        <!-- Product focus -->
        <div>
            <div id="product-options-{{product.id}}-{{currentProductCategory.id}}" data-ng-show="product.showDetails" class="product-details-and-options animate fade">
                <load wait-for="productItemPromise">
                    <div ng-show="loadingOptions" class="text-align-center blue"><i class="fa fa-spin fa-spinner"></i><br /><br /></div>
                    <div ng-show="!loadingOptions">
                        <div class="product-option" data-ng-repeat="option in productItem.options">
                            <label>{{option.displayName}}</label>
                            <!--
                            We have `data-ng-if="!optionItem"` on our default option so that it is not available once an option has been selected
                            -->

                            <!-- Only differentiating option is tag vs description (for route) and setup -->

                            <select
                                data-ng-init="optionItem = getSelectedOption(option)"
                                data-ng-options="getDescription(optionItem, option) for optionItem in getOptions(option) track by optionItem.id"
                                data-ng-model="optionItem"
                                data-ng-change="updateProductOptions(product, option, optionItem)"
                                data-ng-disabled="disableOptions"
                                data-ng-click="$event.stopPropagation()">
                                <option data-ng-if="!optionItem" value="">
                                    {{ 'PLEASE_SELECT' | translate }}
                                </option>
                            </select>

                            <i class="fa option-icon" data-ng-class="{
                        'fa-check green': option.completed && !option.loading,
                        'fa-close red': !option.completed && !option.loading,
                        'fa-spinner fa-spin blue': option.loading
                        }"></i>
                        </div>
                    </div>
                </load>
            </div>
        </div>

    </div>

    <div class="product-price" ng-class="{ 'button-height': product.showDetails }">
            <h7 class="bold" ng-if="!productItem || productItem.productId != product.id">

                <span ng-if="! product.upsellPrice">{{product.price}}</span>

                <span ng-if-start="product.upsellPrice" class="strikethrough">{{product.price}}</span>
                <span ng-if-end class="alert">{{product.upsellPrice}}</span>

            </h7>
            <h7 class="bold" ng-if="productItem.productId == product.id">

                <span ng-if="! product.upsellPrice">{{productItem.price}}</span>

                <span ng-if-start="product.upsellPrice" class="strikethrough">{{productItem.price}}</span>
                <span ng-if-end class="alert">{{productItem.finalPrice}}</span>
            </h7>

            <div ng-if="product.showDetails" class="right">

                <button data-ng-click="buildProduct(product, true, true); $event.stopPropagation();" data-ng-disabled="product.showSpinner || loadingOptions" data-ng-if="(! checkIncompleteOptions(product, productItem)) && showMultibuy(product)" class="font-fix button green buy-action margin-left-10 margin-right-10">
                    <span data-ng-if="product.showSpinner"><i class="fa fa-spinner fa-spin"></i> </span>
                    {{ getSecondaryButtonText(product) | translate: { RES: product.imgQuantity } }}</button>

                <!-- This button is displayed in all contexts -->
                <button data-ng-click="buildProduct(product); $event.stopPropagation();" data-ng-disabled="product.showSpinner || loadingOptions" data-ng-if="(bypassOptions(product) || !checkIncompleteOptions(product, productItem))" class="font-fix button green buy-action">
                    <span data-ng-if="product.showSpinner"><i class="fa fa-spinner fa-spin"></i></span>
                    {{ getPrimaryButtonText(product) | translate: { RES: product.imgQuantity }  }}</button>
            </div>
            <!-- Buttons now go here for purchase -->
    </div>

    <div class="product-pip" ng-if="!loadingOptions && !bypassOptions(product) && product.showDetails && getPipUrl().url">
        <div ng-show="product.showSpinner" class="text-align-center blue"><i class="fa fa-spin fa-spinner"></i></div>
        <div ng-show="! product.showSpinner" class="pip" data-ng-class="{ 'pip-portrait' : generalStore.get('currentPreviewImage').value.preview.width < generalStore.get('currentPreviewImage').value.preview.height, 'pip-landscape' : generalStore.get('currentPreviewImage').value.preview.width > generalStore.get('currentPreviewImage').value.preview.height }"
            data-ng-style="{'background-image': 'url(' + getPipUrl().url + ')'}" ></div>
    </div>
</div>


<div ng-if="currentProductCategory.products.length === 0">
    {{'PRODUCT_UNAVAILABLE' | translate}}
</div>