<load wait-for="loadingPage">
    <div>
        <br />
        <br />
        <div class="desktop-hide" ng-repeat="download in downloads">
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'ORDER_DATE' | translate}}</label>
                <span class="class-input">{{download.orderDate}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'ORDER_NUMBER' | translate}}</label>
                <span class="class-input">{{download.orderNumber}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'DOWNLOAD_PRODUCT_NAME' | translate}}</label>
                <span class="class-input">{{download.productName}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'DOWNLOAD_ZIP_TIMEOUT' | translate}}</label>
                <span class="class-input">{{download.zipTimeout}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'DOWNLOAD_FILE_SIZE' | translate}}</label>
                <span class="class-input">{{download.fileSize}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'STATUS' | translate}}</label>
                <span class="class-input green bold" data-ng-if="download.status == downloadStatus.available">{{'DOWNLOAD_READY' | translate}}</span>
                <span class="class-input yellow bold" data-ng-if="download.status == downloadStatus.processing">{{'PROCESSING_ORDER' | translate}}</span>
                <span class="class-input yellow bold" data-ng-if="download.status == downloadStatus.onHold">{{'ON_HOLD' | translate}}</span>
                <span class="class-input red bold" data-ng-if="download.status == downloadStatus.expired">{{'EXPIRED' | translate}}</span>
            </div>
            <a class="button full-width green" ng-click="startDownload(download)" ng-if="download.status == downloadStatus.available" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_DOWNLOAD' | translate}}</a>
            <br /><br /><br />
        </div>

        <div class="desktop-show" ng-show="downloads.length">
            <table>
                <thead>
                    <tr>
                        <th>{{'ORDER_DATE' | translate}}</th>
                        <th>{{'ORDER_NUMBER' | translate}}</th>
                        <th>{{'DOWNLOAD_PRODUCT_NAME' | translate}}</th>
                        <th>{{'DOWNLOAD_FILE_SIZE' | translate}}</th>
                        <th>{{'STATUS' | translate}}</th>
                    </tr>
                </thead>
                <tbody ng-repeat="download in downloads">
                    <tr>
                        <td>{{download.orderDate}}</td>
                        <td>{{download.orderNumber}}</td>
                        <td>{{download.productName}}</td>
                        <td>{{download.fileSize}}</td>
                        <td class="class-input green bold" data-ng-if="download.status == downloadStatus.available">{{'DOWNLOAD_READY' | translate}}</td>
                        <td class="class-input yellow bold" data-ng-if="download.status == downloadStatus.processing">{{'PROCESSING_ORDER' | translate}}</td>
                        <td class="class-input yellow bold" data-ng-if="download.status == downloadStatus.onHold">{{'ON_HOLD' | translate}}</td>
                        <td class="class-input red bold" data-ng-if="download.status == downloadStatus.expired">{{'EXPIRED' | translate}}</td>
                        <td class="no-border">
                            <a class="button green" ng-click="startDownload(download)" ng-if="download.status == downloadStatus.available" ng-disabled="loading">{{'BUTTON_DOWNLOAD' | translate}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <br />
        </div>

        <div ng-show="!downloads.length"><p>{{'NO_DOWNLOADS_MESSAGE' | translate}}</p><br /></div>
    </div>
</load>