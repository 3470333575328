<div class="col-5 summary right">
    <p class="checkout-heading">{{'SUMMARY' | translate}}</p>
    <div data-ng-repeat="item in summaryData.items" item="item">
        <p ng-if="! item.parentId">{{item.name}} <span class="right">{{item.price}}</span></p>
        <!-- <p data-ng-if="summaryData.discount">{{'DISCOUNTS' | translate}}: <span class="right">-{{summaryData.discount}}</span></p> -->
    </div>
    <hr />
    <div>
        <p>{{'SUBTOTAL' | translate}}:<span class="right">{{summaryData.price}}</span></p>
        <p>{{'SHIPPING' | translate}}:
            <span ng-if="summaryData.shipping" class="right">{{summaryData.shipping}}</span>
            <span ng-if="!summaryData.shipping" class="right italic">{{'CONFIRM_ADDRESS_FOR_SHIPPING' | translate}}</span>
        </p>
        <p style="margin-top:10px;" class="bold">{{'TOTAL' | translate}}: <span class="right">{{summaryData.finalPrice}}</span></p>
        <p style="font-size:12px;">{{'VAT' | translate}}: <span class="right">{{summaryData.vat}}</span></p>
    </div>
</div>
