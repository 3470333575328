<div class="modal">
  <div class="modal_container">
    <h3>{{policy.organisationName}}</h3>

    <div ng-if="policy.translationKey" ng-bind-html="policy.translationKey | translate"></div>

    <div ng-if="policy.orgBlurbKey" ng-bind-html="policy.orgBlurbKey | translate"></div>

    <ul>
      <li ng-repeat="option in policy.options">
          <label>
        <input type="radio" class="margin-reset" ng-value="option.id" ng-model="model.policyOption" ng-change="changeOption()" />
        <span data-ng-bind-html="option.translationKey | translate"></span>
       </label>
        </p>
      </li>
   </ul>
      
    <p>{{ 'DATA_RETENTION_OPTION_FOOTER' | translate: { orgName: policy.organisationName } }}</p>

    <p ng-if="!model.policyOption" class="error">{{'DATA_RETENTION_CHOOSE_OPTION' | translate}}</p>

    <div class="text-center">
    <button class="button green" ng-click="continue()" ng-disabled="loading || !model.policyOption">
      <i data-ng-show="loading" class="fa fa-spin fa-spinner"></i
      >{{ (isLoggedIn ? 'CONTINUE' : 'LOGIN_TO_CONFIRM') | translate }}
    </button>
    </div>
  </div>
</div>
