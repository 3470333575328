<p>
    <span class="checkout-heading">{{'CLASS_DETAILS' | translate}}</span>&nbsp;
    <a ng-click="explainClassDetails()" class="classic-link" ng-show="!explainClass">
        {{'EXPLAIN_BUTTON' | translate}}
    </a>
</p>
<div class="class-details">
    <p ng-show="explainClass">
        <a ng-click="explainClassDetails()"><i class="fa fa-angle-up"></i></a>&nbsp;{{'PLEASE_NOTE' | translate}}
        <br />{{'CLASS_DETAILS_EXPLAINED' | translate}}
    </p>
    <div class="form-group">
        <label class="class-label">{{'CLASS_NAME' | translate}}</label>
        <input class="class-input" type="text" data-ng-model="className" />
    </div>
    <p class="alert" ng-show="!classNameValid">{{'ERROR_CLASSNAME' | translate}}</p>
    <div class="form-group">
        <label class="class-label">{{'CHILD_NAME' | translate}}</label>
        <input class="class-input" type="text" data-ng-model="childName" />
    </div>
    <p class="alert" ng-show="!childNameValid">{{'ERROR_CHILDNAME' | translate}}</p>
</div>
