<div class="confirmCrop">
    <h1>{{'CONFIRM_CROP_TITLE' | translate}}</h1>

    <div class="crop-logo">
        <div class="image-cropper">
            <span class="fa fa-user"></span>
        </div>
        <div class="crop-line"></div>
    </div>

    <div ng-bind-html="'CONFIRM_CROP' | translate"></div>

    <div>
        <a class="button blue" ng-click="close()">{{'REVIEW_BASKET' | translate}}</a>
        <a class="button green" ng-click="continue()">{{'PROCEED_CHECKOUT' | translate}}</a>
    </div>
</div>