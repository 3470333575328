<!-- Generic container for checkout module -->
<div class="checkout-module" ng-class="{'active': isActive()}" ng-if="containsModule()")">
  <h3 ng-class="{'lit': (isActive() || hasBeenActive) }"
      ng-disabled="!hasBeenActive"
      ng-click="show(hasBeenActive)"
  >
      <icon class="icon-check-mark" type="check-mark" ng-if="hasBeenCompleted"></icon>
  {{moduleIndex()}}. {{ moduleName() | translate }}

    <!-- We only allow modules to be visited that have been previously visited. -->
    <a class="right"
       ng-if="hasBeenActive && !isActive()"
       ng-class=""
    >
      <icon class="icon-chevron-down" type="chevron-down"></icon>
    </a>


  </h3>

  <div class="content">
      <ng-transclude></ng-transclude>
  </div>
</div>