
<div class="album-image-folders-container">
    <div data-ng-if="loading" class="col-12 loading-indicator fade"><i class="fa fa-spinner fa-spin"></i></div>
    <load wait-for="imageFolders" data-ng-if="! loading">
        
        <a data-ng-click="decrementFolder()" class="arrow-left desktop-show" ng-if="getCurrentFolderIndex() > 0">
            <i class="fa fa-chevron-left"></i>
        </a>
        <div class="album-image-folders">

            <div class="album-image-folder-scroll" ng-style="{'width': getImageFolderWidth() + 'px' }">
                <div class="folder" ng-class="{'selected': isSelected(folder)}" data-ng-repeat="folder in imageFoldersView"
                    ng-click="selectImageFolder(folder)">
                    <div class="folder-name">
                        <p>{{!folder.displayName || folder.displayName == folder.sittingPassword ? folder.organisationName : folder.displayName}}</p>
                        <p>{{getPrettyEventDate(folder.eventDate)}}</p>
                        <p>{{folder.sittingPassword}}</p>
                    </div>
                    <div>
                        <div class="folder-image" style="background-image:url('{{folder.preview.url}}')">
                        </div>
                    </div>
                    <div ng-if="folder.earlybirdString" class="folder-upsell">
                        <p>{{folder.earlybirdString}}</p>
                    </div>
                </div>
            </div>
        </div>
        <a data-ng-click="incrementFolder()" class="arrow-right desktop-show" ng-if="getCurrentFolderIndex() < (imageFoldersView.length - 1)">
            <i class="fa fa-chevron-right"></i>
        </a>
        <add-sitting ng-if="!modal && imageFolders"></add-sitting>
    </load>
</div>
