<div ng-hide="loadingPage">
    <h1 ng-show="!showReset">{{'LOGIN' | translate}}</h1>
    <div class="form-section" ng-show="!showReset">
        <div class="form-group">
            <label class="class-label sr-only">{{'REGISTER_EMAIL' | translate}}</label>
			<input class="class-input full-width no-mouseflow" type="text" placeholder="{{'REGISTER_EMAIL' | translate}}" data-ng-model="username" ng-keyup="$event.keyCode == 13 && login()" />
        </div>
        <p class="alert" ng-show="invalidUsername">{{'ERROR_EMAIL' | translate}}</p>
        <div class="form-group">
            <label class="class-label sr-only">{{'LOGIN_PASSWORD' | translate}}</label>

            <div class="input-group class-input full-width padding-bottom-20">
                <input ng-if="! showPassword" class="no-mouseflow" type="password" placeholder="{{'LOGIN_PASSWORD' | translate}}" data-ng-model="$parent.password" ng-keyup="$event.keyCode == 13 && login()" /><span ng-if="! showPassword" ng-click="changeShowPassword(true);" class="fa fa-eye"></span>
                <input ng-if="showPassword" class="no-mouseflow" type="text" placeholder="{{'LOGIN_PASSWORD' | translate}}" data-ng-model="$parent.password" ng-keyup="$event.keyCode == 13 && login()" /><span ng-if="showPassword" ng-click="changeShowPassword(false);" class="fa fa-eye-slash"></span>
            </div>
        </div>
        <p class="alert" ng-show="invalidPassword">{{'ERROR_OLDPASSWORD' | translate}}</p>
        <a class="button" ng-disabled="loading" ng-click="login()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'LOGIN' | translate}}</a>&nbsp;&nbsp;
        <a class="classic-link centre-by-height" ng-click="showResetForm()">{{'FORGOT_PASSWORD' | translate}}</a>
        <p class="alert" ng-show="userNotFound">{{loginMessage}}</p>
    </div>
    <h1 ng-show="showReset">{{'CHANGEPASSWORD' | translate}}</h1>
    <div class="form-section" ng-show="showReset">
        <div class="form-group">
            <label class="sr-only">{{'REGISTER_EMAIL' | translate}}</label>
            <input type="text" class="class-input" data-ng-model="form.email" placeholder="{{'REGISTER_EMAIL' | translate}}" ng-keyup="$event.keyCode == 13 && resetPassword()" />
            <p class="alert" ng-show="invalidEmail">{{'ERROR_EMAIL' | translate}}</p>
            <p class="alert" ng-show="userNotFound">{{'ERROR_USER_NOT_FOUND' | translate}}</p>
            <a class="button" ng-disabled="loading" ng-click="resetPassword()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'SEND_BUTTON' | translate }}</a>
        </div>
        <p class="padding-bottom-20">{{'RESET_PASSWORD_EXPLANATION' | translate }}</p>
        <p><a class="button" ng-click="showResetForm()">{{'LOGIN' | translate}}</a></p>
    </div>
</div>