<!-- Let's change the way we render payment methods -->

<!-- We're going to need a method to filter out where the client can not support -->

<ul ng-if="paymentMethods" id="paymentMethods">
    <li ng-repeat="method in paymentMethods">
        <button ng-click="switchPayment(method.key)" ng-class="{'active': switchedPayment == method.key}">
            {{ 'PAYMENTMETHOD_' + method.key.toUpperCase() | translate }}
        </button>
    </li>
</ul>

<div id="paymentContainer">

    <!-- Card provider -->
    <div id="card-provider" class="provider" ng-if="switchedPayment == 'BraintreeCards'">
        <div id="card-wrapper">
            <form class="form" action="/" id="braintreeForm" method="post">

                <ul id="payment-cards-list">
                    <li ng-repeat="(key, value) in faCards">
                        <i class="fa {{key}}" ng-class="{'active': faCardBrand == key}" aria-hidden="true"></i>
                    </li>
                </ul>

                <div class="form-group">
                <label for="card-number" class="class-label">{{ 'PAYMENT_NAME' | translate }}
                </label>
                    <input required name="card-name" class="class-input" type="text" ng-model="paymentData.brainTreeCards.cardName" ng-focus="focusSlide()" />
                </div>

                <div class="form-group">
                    <label for="card-number" class="class-label">{{ 'PAYMENT_CARDNUMBER' | translate }}
                </label>
                    <div id="card-number" class="class-input hosted-field"></div>
                </div>

                <div class="form-group">
                    <label for="cvv" class="class-label">{{ 'PAYMENT_CVV' | translate }}</label>
                    <div id="cvv" class="class-input hosted-field"></div>
                </div>

                <div class="form-group">
                    <label for="expiration-date" class="class-label">{{ 'PAYMENT_EXPIRATION' | translate }}</label>
                    <div id="expiration-date" class="class-input hosted-field"></div>
                </div>
            </form>
        </div>
    </div>

    <!-- Static Wrapper -->
    <div id="static-provider" class="provider" ng-if="isStaticPayment">
        <div id="static-wrapper">

            <div ng-bind-html="'PAYMENT_'+switchedPayment.toUpperCase() | translate"></div>

    </div>
    </div>

    <div ng-if="errorString" class="alert">
        <div ng-bind-html="'PAYMENT_FAILED' | translate"></div>
        <div ng-bind-html="errorString"></div>
        <!-- <div ng-if="errors == 'Gateway Rejected: three_d_secure'">{{ 'THREEDS_ERROR' | translate}}</div> -->
    </div>

    <div class="margin-top-20 text-align-center">
        <button id="submit-button" class="btn btn-width green pay" ng-if="payButtonVisible" ng-disabled="(! paymentEnabled) || (! paymentValid) || isPaying" ng-click="pay(paymentData)">{{ 'PAY_BUTTON' | translate }}<span ng-if="isLoading"> <i class="fa fa-spinner fa-spin"></i></span>
        </button>
    </div>

    <!-- Paypal -->
    <div id="paypal-provider" class="provider" ng-if="switchedPayment == 'BraintreePaypal'">
            <div id="paypal-wrapper">

                <div>
                    <p class="margin-bottom-10">{{ 'PAYMENT_PAYPAL' | translate }}</p>

                    <div id="paypal-button" name="paypal-button">
                    </div>
                </div>
        </div>
    </div>

    <!-- Gpay -->
    <div id="gpay-provider" class="provider" ng-if="switchedPayment == 'BraintreeGpay'">
            <div id="gpay-wrapper">

                <div ng-if="! isLoading">
                    <p class="margin-bottom-10" style="display:none;" id="payment_gpay_invite">{{ 'PAYMENT_GPAY' | translate }}</p>

                    <div id="gpay-button" name="gpay-button">
                    </div>

                    <div class="alert">
                        <p style="display:none;" id="payment_gpay_unavailable">
                            {{'PAYMENT_GPAY_UNAVAILABLE' | translate }}
                        </p>
                    </div>
                </div>

                <div ng-if="isLoading">
                    <p><i class="fa fa-spinner fa-spin"></i> {{ 'LOADING' | translate }}</p>
                </div>
        </div>
    </div>

    <!-- Apple Pay -->
    <div id="applepay-provider" class="provider" ng-if="switchedPayment == 'BraintreeApplepay'">
            <div id="applepay-wrapper">

                <div>
                    <p class="margin-bottom-10" style="display: none;" id="payment_applepay_invite">{{ 'PAYMENT_APPLEPAY' | translate }}</p>

                    <div class="apple-pay-button apple-pay-button-white"></div>

                    <div class="alert">
                        <p style="display: none;" id="payment_applepay_unavailable">
                        {{ 'PAYMENT_APPLEPAY_UNAVAILABLE' | translate }}
                        </p>
                    </div>
                </div>
        </div>
    </div>
</div>