<div ng-hide="loadingPage">
    <h1>{{'SITTING_HINT' | translate}}</h1>
    <div class="form-section" ng-show="!showReset">
        <div class="form-group">
            <label class="class-label sr-only">{{'SITTING_HINT' | translate}}</label>
			<input class="class-input full-width no-mouseflow" type="text" placeholder="{{'SITTING_HINT' | translate}}" ng-model="domain.sittingPassword" ng-keyup="$event.keyCode == 13 && login()" />
        </div>
       <p class="alert" ng-show="invalidPassword">{{'SITTING_ERROR' | translate}}</p>
        <a class="button" ng-disabled="loading" ng-click="login()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'LOGIN' | translate}}</a>&nbsp;&nbsp;
    </div>
</div>