<div class="packReminder modal-pack-reminder">
    <h1 ng-if="modalBase.data.upsell" class="modal-text-block" ng-bind-html="'PACK_REMINDER_MODAL_TITLE' | translate"></h1>

    <div class="modal-text-block">
        <p ng-if="!modalBase.data.upsell" ng-bind-html="'PACK_REMINDER_MODAL_BUY_MORE' | translate: { productName: modalBase.data.product.name, productOptions: modalBase.data.product.selectedOptions[0].item.description || modalBase.data.product.options[0].optionItem.description }"></p>
        <p ng-if="modalBase.data.upsell" ng-bind-html="'PACK_REMINDER_MODAL_BUY_MORE_SAVE' | translate: { productName: modalBase.data.product.name, productOptions: modalBase.data.product.selectedOptions[0].item.description || modalBase.data.product.options[0].optionItem.description }"></p>
    </div>

    <div class="modal-button-box">
        <a class="button blue" ng-click="goToPacks()">{{'YES' | translate}}</a>
        <a class="button blue" ng-click="continue()">{{'NO' | translate}}</a>
    </div>
</div>