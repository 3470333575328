<div class="form-section" ng-show="!isLoading">
    <div class="add-edit" ng-show="addEdit">
        <div class="form-group">
            <label>{{'NAME' | translate}}&nbsp;<span class="alert">*</span></label>
            <input type="text" data-ng-model="addressModel.fullName" />
        </div>
        <p class="alert" ng-show="fields[0].valid == false">{{'ERROR_FULLNAME' | translate}}</p>
        <div class="form-group">
            <label>{{'REGISTER_COUNTRY' | translate}}&nbsp;<span class="alert">*</span></label>
            <select id="addressCountrySelect"
                    data-ng-model="addressModel.country"
                    data-ng-options="country.name for country in countries track by country.id"
                    ng-change="updateCountryId()"></select>
        </div>
        <p class="alert" ng-show="fields[1].valid == false">{{'ERROR_COUNTRY' | translate}}</p>
        <div class="form-group">
            <label>{{'ZIP' | translate}}&nbsp;<span class="alert">*</span></label>
            <input type="text" data-ng-model="addressModel.postcode" maxlength="9" />
            <!-- <a class="button" ng-click="postcodeLookup(addressModel.countryId)">{{'ADDRESS_LOOKUPPOSTCODE_BUTTON' | translate}}</a> -->
        </div>
        <p class="alert" ng-show="fields[2].valid == false">{{'ERROR_POSTCODE' | translate}}</p>
        <div class="form-group">
            <label>{{'LINE_1' | translate}}&nbsp;<span class="alert">*</span></label>
            <input type="text" data-ng-model="addressModel.houseStreet" />
        </div>
        <p class="alert" ng-show="fields[3].valid == false">{{'ERROR_STREET' | translate}}</p>
        <div class="form-group">
            <label>{{'LINE_2' | translate}}</label>
            <input type="text" data-ng-model="addressModel.area" />
        </div>
        <div class="form-group">
            <label>{{'TOWN' | translate}}&nbsp;<span class="alert">*</span></label>
            <input type="text" data-ng-model="addressModel.city" />
        </div>
        <p class="alert" ng-show="fields[4].valid == false">{{'ERROR_CITY' | translate}}</p>
   </div>
    <div class="form-group" id="submit">
        <a class="button cancel" ng-show="addEdit" ng-disabled="loading" ng-click="cancel()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_CANCEL' | translate}}</a>
        <a class="button green submit" ng-click="save()" ng-disabled="loading" ng-show="addEdit"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_SAVE' | translate}}</a>
    </div>
</div>