<div data-ng-repeat="item in approvalItems track by $index">
    <div class="approval-item">
        <img src="{{item.preview.url}}"
            id="approval_{{$index}}"
            ng-click="previewImage(item)"
       />
       <p><i>{{'ENLARGE_IMAGE' | translate}}</i></p>
    </div>
    <div class="approval-item bold">
        {{item.productName}}
    </div>
    <div class="approval-item">
        <textarea rows="10" ng-model="item.comments" ng-change="toggleButtons($index, item.comments)" />
    </div>
    <div class="approval-item" ng-disabled="loading">
        <a class="button cancel" ng-click="revise(item.approvalItemId, item.comments)" ng-disabled="!item.revisionEnabled">
            <i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_REVISE' | translate}}
        </a>
    </div>
    <div class="approval-item" ng-disabled="loading">
        <a class="button" ng-click="approve(item.approvalItemId)" ng-disabled="item.revisionEnabled" ng-show="!revisionView"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_APPROVE' | translate}}</a>
    </div>
    <p class="right">{{item.alert | translate}}</p>
<hr class="divider"/>
</div>
