<a id="crop" class="filter-preview button" ng-click="showCropImage(image.position)">
	<i ng-show="loading" class="fa fa-spin fa-spinner"></i>
	<i ng-hide="loading" class="fa fa-crop"></i>&nbsp;
	<span>{{ 'CROP' | translate }}</span>
</a>
<div class="modal-root" ng-if="cropBox.showCrop">
	<div class="modal" id="myModal">
		<div class="modal-header">
			<a ng-click="resetCropBox(image.position)" class="button green continue" ng-disabled="loading">
				<span>
					<i class="fa fa-spin fa-spinner" ng-show="loading"></i><i class="fa fa-repeat" ng-show="!loading"></i>&nbsp;{{'BUTTON_RESET' | translate}}
				</span>
			</a>
			<a ng-click="saveCropBox()" class="button green continue" ng-disabled="loading">
				<span>
					<i class="fa fa-spin fa-spinner" ng-show="loading"></i><i class="fa fa-check" ng-show="!loading"></i>&nbsp;{{'BUTTON_SAVE' | translate}}
				</span>
			</a>
			<a ng-click="hideCropImage()" class="button green continue" ng-disabled="loading">
				<span>
					<i class="fa fa-spin fa-spinner" ng-show="loading"></i><i class="fa fa-close" ng-show="!loading"></i>&nbsp;{{'BUTTON_CLOSE' | translate}}
				</span>
			</a>
		</div>
		<!-- This defines the content section of our modal. -->
		<div class="modal-content">
			<div id="background" style="margin-bottom: 10px;" ng-style="{'width':cropBox.image.width + 'px','height': cropBox.image.height + 'px'}">
				<div id="draggable-box" class="cropbox" ng-style="{'width':(cropBox.image.width * (cropBox.x1 - cropBox.x0)) + 'px','height': (cropBox.image.height * (cropBox.y1 - cropBox.y0) ) + 'px'}">
					<div id="top-left-handle" />
					<div id="top-right-handle" />
					<div id="bottom-left-handle" />
					<div id="bottom-right-handle" />
				</div>
				<div id="foreground" ng-style="{'width':(cropBox.image.width * (cropBox.x1 - cropBox.x0)) + 'px','height': (cropBox.image.height * (cropBox.y1 - cropBox.y0) ) + 'px'}"></div>
				<img class="croppable-image" src="{{cropBox.image.url}}" ng-style="{'width':cropBox.image.width + 'px','height': cropBox.image.height + 'px'}">
			</div>
			<a id="colour" class="filter-preview classic-link" ng-click="applyTint(0,image.position)">
				<i ng-show="colourLoading" class="fa fa-spin fa-spinner"></i>
				<icon type="adjust-colour"></icon>
				<span>{{ 'COLOUR' | translate }}</span>
			</a>
			<a id="black-and-white" class="filter-preview classic-link" ng-click="applyTint(1,image.position)">
				<i ng-show="bwLoading" class="fa fa-spin fa-spinner"></i>
				<icon type="adjust-bw"></icon>
				<span>{{ 'BLACK_AND_WHITE' | translate }}</span>
			</a>
			<!-- <a id="sepia" class="filter-preview classic-link" ng-click="applyTint(2,image.position)">
				<i ng-show="sepiaLoading" class="fa fa-spin fa-spinner"></i>
				<icon type="adjust-sepia"></icon>
				<span>{{ 'SEPIA' | translate }}</span>
			</a> -->
		</div>
	</div>
</div>
