<div>

    <h1>{{content.errorHeader}}</h1>

    <div ng-bind-html="content.errorBody"></div>

    <a href="{{link}}" class="button margin-top-20">{{'NOTIFYTHANKS_BACK' | translate}}</a>

    <footer class="footer-text" style="margin-top:20px;">Time: {{now | date:'yyyy-MM-dd HH:mm:ss Z'}} <br />
    IP Address: {{test.ipAddress}}</footer>
</div>