
<load wait-for="loadingPage">
    <div data-ng-if="basketItemsCount" class="col-12">
        <h1>{{'BASKET_HEADER_ITEMS' | translate: basketItemsCount}}</h1>
        <div data-ng-if="basketItemsCount.basketItemsCount == 0">
            <p>{{'BASKET_NO_ITEMS_MESSAGE' | translate}}</p>
        </div>
        <a ng-if="showClearBasket" class="button blue" ng-click="clearBasket()">Dev: Clear Basket</a>
        <a class="button green" ng-class="{ 'right': !$root.isRtlLanguage, 'left': $root.isRtlLanguage }" data-ng-show="basketData.basketItems.length && !isLoggedIn && isValidatedSession" ng-click="redirectToRegister()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>{{'REGISTER_FOR_CHECKOUT' | translate}}</a>
        <a class="button green" ng-class="{ 'right': !$root.isRtlLanguage, 'left': $root.isRtlLanguage }" data-ng-show="basketData.basketItems.length && !isLoggedIn && !isValidatedSession" ng-click="redirectToLogin()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>{{'LOGIN_FOR_CHECKOUT' | translate}}</a>
        <a class="button right green" ng-class="{ 'right': !$root.isRtlLanguage, 'left': $root.isRtlLanguage }" data-ng-show="basketData.basketItems.length && isLoggedIn" ng-click="confirmBasket()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>{{'GO_TO_CHECKOUT' | translate}}</a>
        <h4 class="heading-4" ng-if="basketData.basketItems.length && basketData.price != basketData.preDiscountPrice">{{'TOTAL' | translate}}: <span class="strikethrough">{{basketData.preDiscountPrice}}</span> <span class="alert">{{basketData.price}}</span> </h4>
        <h4 class="heading-4" ng-if="basketData.basketItems.length && basketData.price == basketData.preDiscountPrice">{{'TOTAL' | translate}}: {{basketData.price}}</h4>
        <br /><br />
        <basket-item data-ng-repeat="item in basketData.basketItems" item="item" basket="basketData.basketItems" basket-count="basketItemsCount" update-basket-data="updateBasketData(data)"></basket-item>
        <a class="button right green" ng-class="{ 'right': !$root.isRtlLanguage, 'left': $root.isRtlLanguage }" data-ng-show="basketData.basketItems.length && !isLoggedIn && isValidatedSession" ng-click="redirectToRegister()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>{{'REGISTER_FOR_CHECKOUT' | translate}}</a>
        <a class="button right green" ng-class="{ 'right': !$root.isRtlLanguage, 'left': $root.isRtlLanguage }" data-ng-show="basketData.basketItems.length && !isLoggedIn && !isValidatedSession" ng-click="redirectToLogin()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>{{'LOGIN_FOR_CHECKOUT' | translate}}</a>
        <a class="button right green" ng-class="{ 'right': !$root.isRtlLanguage, 'left': $root.isRtlLanguage }" data-ng-show="basketData.basketItems.length && isLoggedIn" ng-click="confirmBasket()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>{{'GO_TO_CHECKOUT' | translate}}</a>
        <div style="height: 120px"></div><!-- This is to ensure the buttons clear the footer -->
    </div>
</load>