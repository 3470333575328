<div class="col-5 mobile_summary">
    <a data-ng-click="toggleItems()" ng-if="!showItems">{{ 'SHOW_BASKET_ITEMS' | translate}} <i class="fa fa-chevron-down"></i></a>
    <a data-ng-click="toggleItems()" ng-if="showItems">{{ 'HIDE_BASKET_ITEMS' | translate }} <i class="fa fa-chevron-up"></i></a>
    <hr ng-if="showItems"/>
    <div ng-show="showItems" data-ng-repeat="item in summaryData.items">
        <p ng-if="! item.parentId">{{item.name}} <span class="right">{{item.price}}</span></p>
    </div>
    <hr />

   <div>
        <p>{{'SUBTOTAL' | translate}}:<span class="right">{{summaryData.price}}</span></p>
        <p>{{'SHIPPING' | translate}}:
            <span ng-if="summaryData.shipping" class="right">{{summaryData.shipping}}</span>
            <span ng-if="!summaryData.shipping" class="right italic text-75em">{{'CONFIRM_ADDRESS_FOR_SHIPPING' | translate}}</span>
        </p>
        <p style="margin-top:10px;" class="bold">{{'TOTAL' | translate}}: <span class="right">{{summaryData.finalPrice}}</span></p>
        <p style="font-size:12px;">{{'VAT' | translate}}: <span class="right">{{summaryData.vat}}</span></p>
    </div>
</div>
