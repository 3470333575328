<div ng-hide="loadingPage">
    <h1>{{'NOTIFYME_HEADER' | translate}}</h1>

    <div ng-bind-html="'NOTIFYME_BODY' | translate"></div>

    <form class="form-section" ng-submit="submit()" autocomplete="on">

        <div class="form-group">
            <label class="class-label">{{'NOTIFYME_FULLNAME' | translate}}</label>
            <input id="fullname" name="fullname" autocomplete="name" type="text" data-ng-model="domain.fullName" ng-blur="validateFieldIndex(0)" />

            <p class="alert" ng-show="userFields[0].valid == false">{{'ERROR_FULLNAME' | translate}}</p>
        </div>

        <div class="form-group">
            <label class="class-label">{{'REGISTER_EMAIL' | translate}}</label>
            <input id="email" name="email" type="text" autocomplete="email" data-ng-model="domain.emailAddress" ng-blur="validateFieldIndex(1)" />

            <p class="alert" ng-show="userFields[1].valid == false">{{'ERROR_EMAIL' | translate}}</p>
        </div>

        <button type="submit" class="button" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>
            {{'NOTIFYME_BUTTON' | translate}}</button>
    </form>

    <div ng-bind-html="'NOTIFYME_PRIVACY' | translate"></div>

    <p class="margin-top-20"><a class="classic-link" href="{{backLink}}">{{'NOTIFYME_BACK' | translate}}</a></p>
</div>
