<div class="box" ng-if="stage == 1">
  <p class="pre">{{getAddress().lines.join("\n")}}</p>
    <p ng-if="!getAddress().id">{{ 'PLEASE_SELECT_AN_ADDRESS_BEFORE_CONTINUING' | translate }}</p>
  <button ng-class="::settable === true ? 'btn-half' : 'btn-width'"
          class="btn green"
          ng-disabled="!getAddress().id"
          ng-click="continue()">{{ 'CONTINUE' | translate }}</button>
  <button ng-if="settable"
          class="btn btn-half white"
          ng-click="change(2);">{{ 'CHANGE_ADDRESS' | translate }}</button>
</div>

<div class="box" ng-if="stage == 2">

    <ul>
        <li ng-repeat="a in addresses">
            <label class="address-radio">
            <input type="radio" class="margin-reset" name="address_{{::$id}}" ng-value="a.id" ng-model="$parent.bind.selectedAddressId" />
            {{a.concatLines}}
            </label>
        </li>
    </ul>

    <button class="btn btn-half green" ng-disabled="!getAddress().id" ng-click="continue()">{{ 'USE_SELECTED_ADDRESS' | translate }}</button>
    <button class="btn btn-half white" ng-click="change(3);">{{ 'ADDADDRESS_BUTTON' | translate }}</button>
</div>

<div class="box" ng-if="stage == 3">

    <div class="form">

		<div class="form-group">
			<label class="margin-bottom-5">{{ 'SELECT_COUNTRY' | translate }}</label>
			<select id="country"
					data-ng-model="country"
					data-ng-options="country.name for country in countries track by country.id"
					ng-change="changeCountry(country.id)"></select>
		</div>

        <div class="form-group" ng-if="filterByCountry && selectedCountry.deliveryFilter" >
            <p class="error">{{ 'DELIVERY_FILTER_' + selectedCountry.deliveryFilter | translate }}</p>
        </div>

        <div class="form-group" ng-disabled="!selectedCountry" ng-if="! filterByCountry || ! selectedCountry.deliveryFilter">
            <label class="margin-bottom-5" for="search-bar_{{::$parent.$parent.$id}}">{{ 'SEARCH_FOR_ADDRESS_' + (selectedCountry.fetchifyFidelity || 0) | translate }}</label>
            <input type="text" ng-disabled="!selectedCountry" class="form-control address" id="search-bar_{{::$parent.$parent.$id}}" placeholder="Enter address" />
        </div>

    </div>

    <div class="padding-bottom-10 padding-top-5 text-75em" ng-if="selectedCountry && !selectedCountry.deliveryFilter">
        <a ng-click="change(4)">{{ 'ADDRESS_MANUAL' | translate }}</a>
    </div>

    <button class="btn btn-width white" ng-if="(! setIfConditionCheck()) || addresses.length" ng-click="change(2)">{{ 'BACK' | translate }}</button>
</div>

<div class="box" ng-if="stage == 4">

    <div class="form">

        <div class="form-group">
            <div class="form-group">
                <label for="name_{{::$id}}">{{ 'NAME' | translate }}</label>
                <input type="text" class="form-control address" id="name_{{::$id}}" ng-model="fetchifyAddress.fullName" maxlength="255" />
                <p class="error" ng-if="isEmpty(fetchifyAddress.fullName)">{{ 'ERROR_FULLNAME' | translate }}</p>
            </div>
            <div class="form-group">
                <label for="address-line-1_{{::$id}}">{{ 'LINE_1' | translate }}</label>
                <input type="text" class="form-control address" id="address-line-1_{{::$id}}" placeholder="" ng-model="fetchifyAddress.line_1" maxlength="100">
                <p class="error" ng-if="isEmpty(fetchifyAddress.line_1)">{{ 'ERROR_STREET' | translate }}</p>
            </div>

            <div class="form-group">
                <label for="address-line-2_{{::$id}}">{{ 'LINE_2' | translate }}</label>
                <input type="text" class="form-control address" id="address-line-2_{{::$id}}" placeholder="" ng-model="fetchifyAddress.line_2" maxlength="100">
            </div>

            <div class="form-group">
                <label for="address-zip_{{::$id}}">{{ 'ZIP' | translate }}</label>
                <input type="text" class="form-control address" id="address-zip_{{::$id}}" placeholder="" ng-model="fetchifyAddress.postal_code" maxlength="10">
                <p class="error" ng-if="isEmpty(fetchifyAddress.postal_code)">{{ 'ERROR_POSTCODE' | translate }}</p>
            </div>

            <div class="form-group">
                <label for="address-town_{{::$id}}">{{ 'TOWN' | translate }}</label>
                <input type="text" class="form-control addresss" id="address-town_{{::$id}}" placeholder="" ng-model="fetchifyAddress.locality" maxlength="100">
                <p class="error" ng-if="isEmpty(fetchifyAddress.locality)">{{ 'ERROR_CITY' | translate }}</p>
            </div>

            <div class="form-group">
                <label for="address-country_{{::$id}}">{{ 'COUNTRY' | translate }}</label>
                <input type="text" class="form-control address" id="address-country_{{::$id}}" placeholder="" readonly ng-model="fetchifyAddress.country_name">
            </div>
        </div>

        <button class="btn btn-half green" ng-disabled="! validateForm()" ng-click="saveAddress()">{{ 'USE_THIS_ADDRESS' | translate }}</button>
        <button class="btn btn-half white" ng-click="change(3)">{{ 'BACK' | translate }}</button>

    </div>
</div>
