<p>
	<span class="checkout-heading">
		{{addressTitle | translate}}
	</span>
	<a ng-show="addresses.length" class="change-btn" ng-click="change()">
		{{'CHANGEADDRESS_BUTTON' | translate}}
	</a>
	<a ng-show="!addresses.length" class="change-btn" ng-click="add()">
		{{'ADDADDRESS_BUTTON' | translate}}
	</a>
</p>

<!-- View simplified list of Addresses while changing address during Checkout -->
<div class="form addressList" ng-show="!addEdit && !isLoading && changingAddress">
	<div ng-show="addresses.length" ng-repeat="address in addresses">
		<!--<input type="radio" name="{{addressType}}" ng-model="address"
			   ng-value="address" ng-click="update(addressType, address, $index)">-->
		<i class="fa fa-circle-o checkable" id="radio-button-{{addressType}}-{{$index}}" ng-click="check($index, addressType, addresses.length);update($index, addressType, addresses.length,  address)"></i>
		<span class="middle" id="{{addressType}}-{{$index}}">
			{{address.concatLines}}
		</span>
	</div>
	<div ng-show="!addresses.length || addresses.length == 0">
		<p>{{'NOADDRESSES_MESSAGE' | translate}}</p><br />
	</div>
	<div ng-show="addresses.length > 0">
		<a ng-click="add()">&nbsp;<i class="fa fa-plus 2x"></i></a><a ng-click="add()">&nbsp;{{'ADDADDRESS_BUTTON' | translate}}</a>
	</div>
</div>

<!-- View top address in the list by default in checkout area -->

<div class="form addressList" ng-show="!addEdit && !isLoading && !changingAddress">
	<div ng-model="defaultAddress">
		<div ng-show="addresses.length > 0">
			<p class="default-address">
				{{defaultAddress.concatLines}}
			</p>

		</div>
		<div ng-show="!addresses.length || addresses.length == 0">
			<p>{{'NOADDRESSES_MESSAGE' | translate}}</p>
		</div>

	</div>
</div>

<!--Add new address-->
<div class="form-section" ng-show="addEdit">
	<div class="add-edit">
		<p class="alert">{{alert | translate}}</p>
		<div class="form-group">
			<label>{{'ADDRESS_FULLNAME' | translate}}&nbsp;<span class="alert">*</span></label>
			<input type="text" data-ng-model="newAddress.fullName" />
		</div>
		<p class="alert" ng-show="fields[0].valid == false">{{'ERROR_FULLNAME' | translate}}</p>
		<div class="form-group">
			<label>{{'REGISTER_COUNTRY' | translate}}&nbsp;<span class="alert">*</span></label>
			<select id="addressCountrySelect-{{addressType}}"
					data-ng-model="country"
					data-ng-options="country.name for country in countries track by country.id"
					ng-change="updateCountry(country.id)"></select>
		</div>
		<p class="alert" ng-show="fields[1].valid == false">{{'ERROR_COUNTRY' | translate}}</p>
		<div class="form-group">
			<label>{{'ADDRESS_POSTCODE' | translate}}&nbsp;<span class="alert">*</span></label>
			<input type="text" data-ng-model="newAddress.postcode" maxlength="9" />
			<a class="button" ng-click="postcodeLookup(newAddress.countryId, newAddress.fullName)">{{'ADDRESS_LOOKUPPOSTCODE_BUTTON' | translate}}</a>
		</div>
		<p class="alert" ng-show="fields[2].valid == false">{{'ERROR_POSTCODE' | translate}}</p>
		<div class="form-group">
			<label>{{'ADDRESS_HOUSENUMBER' | translate}}&nbsp;<span class="alert">*</span></label>
			<input type="text" data-ng-model="newAddress.number" />
		</div>
		<p class="alert" ng-show="fields[3].valid == false">{{'ERROR_HOUSENUMBER' | translate}}</p>
		<div class="form-group">
			<label>{{'ADDRESS_STREET' | translate}}&nbsp;<span class="alert">*</span></label>
			<input type="text" data-ng-model="newAddress.houseStreet" />
		</div>
		<p class="alert" ng-show="fields[4].valid == false">{{'ERROR_STREET' | translate}}</p>
		<div class="form-group">
			<label>{{'ADDRESS_AREA' | translate}}</label>
			<input type="text" data-ng-model="newAddress.area" />
		</div>
		<div class="form-group">
			<label>{{'ADDRESS_CITY' | translate}}&nbsp;<span class="alert">*</span></label>
			<input type="text" data-ng-model="newAddress.city" />
		</div>
		<p class="alert" ng-show="fields[5].valid == false">{{'ERROR_CITY' | translate}}</p>
		<div class="form-group">
			<label>{{'ADDRESS_COUNTY' | translate}}</label>
			<input type="text" data-ng-model="newAddress.county" />
		</div>
	</div>
    <div ng-if="canCopyAddress">
		<br />
		<i id="{{addressType}}-address-copy" class="fa fa-square-o checkable" ng-click="addressCopy(addressType)"></i><span class="middle"> {{ addressToCopy | translate }}</span>
		<br />
	</div>
	<div class="form-group" id="submit">
		<a class="button cancel" ng-disabled="loading" ng-click="cancel()"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_CANCEL' | translate}}</a>
		<a class="button green submit" ng-click="save('invoice')" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_SAVE' | translate}}</a>
	</div>

</div>
