<!-- <cookie-message></cookie-message> -->
<br /><br />
<login class="col-5"></login>

<div class="col-5" ng-if="showRegisterMessage" style="float: right">

    <h1>{{'REGISTER_HINT' | translate}}</h1>

    <p class="margin-top-20"><a class="button wide" href="/register">{{'REGISTER' | translate}}</a></p>

    <div class="desktop-show" ng-bind-html="'REGISTER_BLURB' | translate"></div>

    <div class="desktop-hide margin-top-20" ng-bind-html="'REGISTER_BLURB' | translate"></div>

</div>

<div class="col-5" ng-if="showAddSittingMessage" style="float: right">

    <h1 ng-if="isLoggedIn" class="padding-bottom-20">{{'ADD_SITTING_HINT_LOGGED_IN' | translate}}</h1>
    <h1 ng-if="!isLoggedIn" class="padding-bottom-20">{{'ADD_SITTING_HINT_LOGGED_OUT' | translate}}</h1>

    <div class="desktop-show" ng-bind-html="'ADD_SITTING_BLURB' | translate"></div>

    <div class="desktop-hide margin-top-20" ng-bind-html="'ADD_SITTING_BLURB' | translate"></div>

    <p class="margin-top-20"><a class="button wide" href="{{homeUrl}}">{{'HOME' | translate}}</a></p>

</div>

<div class="clearfix"></div>