<label class="row">
    <input type="checkbox"
           class="margin-reset"
           ng-model="termsAccepted"
           />
    <p class="margin-left-5">

    {{ 'TERMS_AND_CONDITIONS_CONFIRM' | translate }}
    <a ng-href="{{ generateUrl('terms') }}" target="_blank" class="classic-link">{{'TERMS' | translate }}</a>
    </p>
</label>