<div class="box">
    <div class="form">

        <div class="form-group">
            <div class="form-group"
                 ng-if="::templateConditions.requiresTelephone"
            >
                <label for="address-zip" class="margin-bottom-5">{{ 'TELEPHONE' | translate }}</label>
                <input type="tel" class="form-control margin-bottom-10" id="telephone" placeholder="" ng-change="preventAlpha()" ng-model="model().summary.telephone">
            </div>

            <div class="form-group"
                 ng-if="::templateConditions.requiresAdditional"
            >
                <label for="address-line-1" class="margin-bottom-5">{{ 'CLASS_NAME' | translate }}</label>
                <input type="text" class="form-control margin-bottom-10" id="class-name" placeholder="" ng-model="model().summary.additional.className">
            </div>

            <div class="form-group"
                 ng-if="::templateConditions.requiresAdditional"
            >
                <label for="address-line-2" class="margin-bottom-5">{{ 'CHILD_NAME' | translate }}</label>
                <input type="text" class="form-control margin-bottom-10" id="childs-name" placeholder="" ng-model="model().summary.additional.childName">
        </div>

       </div>

        <button class="btn btn-width green"
                ng-disabled="formInvalid()"
                ng-click="continue()">{{ 'CONTINUE' | translate }}</button>
    </div>

</div>