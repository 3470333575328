<load wait-for="loadingPage">
    <div>
        <br /><br />
        <div class="desktop-hide" ng-repeat="order in orders">
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'ORDER_DATE' | translate}}</label><br />
                <span class="class-input">{{order.orderDate}}</span>
            </div>
            <br />
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'ORDER_NUMBER' | translate}}</label><br />
                <span class="class-input">{{order.orderNumber}}</span>
            </div>
            <br />
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'DESCRIPTION' | translate}}</label><br />
                <span class="class-input">{{order.description}}</span>
            </div>
            <br />
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'TOTAL' | translate}}</label><br />
                <span class="class-input">{{order.total}}</span>
            </div>
            <br />
            <a class="button full-width" ng-href="/Orders/{{order.id}}?reorder" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_VIEW_ORDER' | translate}}</a>
            <a class="button full-width" style="margin-top:5px;" href="/DisplayInvoice/get/{{order.id}}" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_VIEW_INVOICE' | translate}}</a>
            <br /><br /><br />
        </div>

        <div class="desktop-show" ng-show="orders.length">
            <table>
                <thead>
                    <tr>
                        <th>{{'ORDER_DATE' | translate}}</th>
                        <th>{{'ORDER_NUMBER' | translate}}</th>
                        <th>{{'DESCRIPTION' | translate}}</th>
                        <th>{{'TOTAL' | translate}}</th>
                    </tr>
                </thead>
                <tbody ng-repeat="order in orders">
                    <tr>
                        <td>{{order.orderDate}}</td>
                        <td>{{order.orderNumber}}</td>
                        <td>{{order.description}}</td>
                        <td>{{order.total}}</td>
                        <td class="no-border">
                            <a class="button" ng-href="/Orders/{{order.id}}?reorder" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_VIEW_ORDER' | translate}}</a>
                        </td>
                        <td class="no-border">
                            <a class="button" href="/DisplayInvoice/get/{{order.id}}" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_VIEW_INVOICE' | translate}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
        </div>
        <div ng-show="!orders.length"><p>{{'NO_ORDERS_MESSAGE' | translate}}</p><br /></div>
    </div>
</load>
