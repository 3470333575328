<div ng-show="!isLoading">
    <span ng-show="nav">{{'ACCOUNT' | translate}}</span>
    <br />

    <div ng-repeat="item in items">
        <div class="account-button">
            <a class="account-icon cell" id="item.value" data-ng-href="{{item.value}}">
                <i class={{item.icon}}></i>&nbsp;
            </a>
            <a class="account-link cell" id="item.value" data-ng-href="{{item.value}}">
                {{item.description | translate}}
            </a>
        </div>
    </div>
</div>