<div class="summary-mobile">
    <p class="checkout-heading">{{'SUMMARY' | translate}}</p>
    <p>{{'ORDER_DATE' | translate}}:<span class="right">{{order.orderDate}}</span></p>
    <p>{{'ORDER_NUMBER' | translate}}: <span class="right">{{order.orderNumber}}</span></p>
    <p>{{'SHIPPING' | translate}}: <span class="right">{{order.shipping}}</span></p>
    <p>{{'TOTAL' | translate}}: <span class="right">{{order.finalPrice}}</span></p>
    <p>{{'DISCOUNTS' | translate}}: <span class="right">{{order.discount}}</span></p>
    <p>{{'SHIPPING' | translate}}: <span class="right">{{order.shipping}}</span></p>
    <p>{{'VAT' | translate}}: <span class="right">{{order.totalVat}}</span></p>
    <hr />
    <p class="checkout-heading">{{'DELIVERY_ADDRESS' | translate}}</p>
    <div ng-repeat="line in order.invoiceAddress.lines">
        <p>{{line}}</p>
    </div>
    <hr />
    <p class="checkout-heading">{{'INVOICE_ADDRESS' | translate}}</p>
    <div ng-repeat="line in order.shippingAddress.lines">
        <p>{{line}}</p>
    </div>
    <hr />
</div>