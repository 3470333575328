<load wait-for="loadingPage">

    <div>
        <br /><br />
        <div class="desktop-hide" ng-repeat="approval in approvals">
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'ORDER_DATE' | translate}}</label>
                <span class="class-input">{{approval.orderDate}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'ORDER_NUMBER' | translate}}</label>
                <span class="class-input">{{approval.orderNumber}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'DOWNLOAD_PRODUCT_NAME' | translate}}</label>
                <span class="class-input">{{approval.productName}}</span>
            </div>
            <a class="button full-width" ng-click="view(approval.id)" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_VIEW_ITEM' | translate}}</a>
            <br /><br /><br />
        </div>

        <div class="desktop-show" ng-show="approvals.length">
            <table>
                <thead>
                    <tr>
                        <th>{{'ORDER_DATE' | translate}}</th>
                        <th>{{'ORDER_NUMBER' | translate}}</th>
                        <th>{{'DOWNLOAD_PRODUCT_NAME' | translate}}</th>
                    </tr>
                </thead>
                <tbody ng-repeat="approval in approvals">
                    <tr>
                        <td>{{approval.orderDate}}</td>
                        <td>{{approval.orderNumber}}</td>
                        <td>{{approval.productName}}</td>
                        <td class="no-border">
                            <a class="button" ng-click="view(approval.id)" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_VIEW_ITEM' | translate}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <br />
        </div>

        <div ng-show="!approvals.length"><p>{{'NO_APPROVALS_MESSAGE' | translate}}</p><br /></div>
    </div>
</load>