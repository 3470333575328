<load wait-for="loadingPage">
    <div class="order-top">
        <h3 class="order-header">{{ heading | translate }}</h3>
        <div class="order-summary-block">
            <div class="margin-bottom-20" ng-if="order.onHold" ng-bind-html="(blurbKey | translate).$$unwrapTrustedValue() | replaceUrlPart:'#WWWURL#':config.wwwUrl:true"></div>
            <div class="order-summary-item">
                <label class="order-summary-item-header">{{ 'ORDER_NUMBER' | translate }}:</label>
                <p class="order-summary-item-data">{{order.orderNumber}}</p>
            </div>
            <div class="order-summary-item">
                <label class="order-summary-item-header">{{ 'ORDER_DATE' | translate }}:</label>
                <p class="order-summary-item-data">{{order.orderDate | moment:'Do MMMM YYYY'}}</p>
            </div>
            <div class="order-summary-item">
                <label class="order-summary-item-header">{{ 'TOTAL_AMOUNT' | translate }}:</label>
                <p class="order-summary-item-data">{{order.finalPrice}}</p>
            </div>
        <div class="order-summary-item">
                <label class="order-summary-item-header">{{ 'INC_SHIPPING_OF' | translate }}:</label>
                <p class="order-summary-item-data">{{order.shipping}}</p>
            </div>
            <div class="order-summary-item">
                <label class="order-summary-item-header">{{ 'INC_DISCOUNTS_OF' | translate }}:</label>
                <p class="order-summary-item-data">{{order.discount}}</p>
            </div>
            <div class="order-summary-item">
                <label class="order-summary-item-header">{{ 'VAT' | translate }}:</label>
                <p class="order-summary-item-data">{{order.totalVat}}</p>
            </div>
            <div class="order-summary-item">
                <label class="order-summary-item-header">{{ 'PAYMENT_STATUS' | translate }}:</label>
                <p class="order-summary-item-data">{{ order.paymentStatusKey | translate }}</p>
            </div>
            <div ng-if="!order.onHold" class="order-summary-item margin-top-20-i">
                <label class="order-summary-item-header">{{ 'PLEASE_CLICK_HERE_FOR' | translate }}</label>
                <a class="order-summary-item-data" ng-href="{{order.whatHappensNextUrl}}" target="_blank">{{ 'INFORMATION_ABOUT_WHAT_HAPPENS_NEXT_LINK' | translate }}</a>
            </div>
            <div class="order-summary-item margin-top-20-i">
                <label class="order-summary-item-header">{{ 'DELIVERY_ADDRESS' | translate }}:</label>
                <p class="order-summary-item-data">{{order.shippingAddress.concatLines}}</p>
            </div>
            <div ng-if="!order.onHold" class="order-summary-item margin-top-20-i">
                <label class="order-summary-item-header">{{ 'INVOICE' | translate }}:</label>
                <a class="order-summary-item-data" ng-href="/DisplayInvoice/get/{{order.id}}" target="_blank">{{ 'CLICK_HERE_TO_VIEW_YOUR_INVOICE' | translate }}</a>
            </div>
        </div>
    </div>

    <div class="order-order-items">
        <h3 class="margin-bottom-15">{{ 'YOUR_ORDER_ITEMS' | translate }}</h3>
        <div class="order-item" ng-repeat="item in order.items">
            <div class="order-item-image">
                <a data-ng-if="item.download && item.download.status == downloadStatus.available" ng-click="startDownload(item.download)"><img ng-src="{{item.pip.url}}" /></a>
                <img data-ng-if="!item.download || item.download.status != downloadStatus.available" ng-src="{{item.pip.url}}" />
                <a ng-if="showReorder" class="button order-item-reorder" ng-click="clone(item.itemId)" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_REORDER' | translate}}</a>
            </div>
            <div class="order-item-meta">
                <p class="bold">{{item.name}}</p>
                <p class="item-meta"><span ng-repeat="opt in item.options">{{opt.description}}<span ng-if="!$last">, </span></span></p>
                <p ng-if="! item.childItems" class="item-meta margin-top-5 margin-bottom-5">{{item.images}}</p>
                <div class="stack">
                    <span class="stacked-left">{{'QTY' | translate}}</span><span class="stacked-right">{{item.quantity}}</span>
                </div>
                <div class="stack">
                    <span class="stacked-left">{{'PRICE' | translate}}</span><span class="stacked-right">{{item.price}}</span>
                </div>
                <div class="stack">
                    <span class="stacked-left">{{'SUBTOTAL' | translate}}</span><span class="stacked-right">{{item.total}}</span>
                </div>
                <div class="stack" ng-if="item.download">
                    <span class="stacked-left">{{'DOWNLOAD_STATUS' | translate}}</span>
                    <span class="stacked-right green" data-ng-if="item.download.status == downloadStatus.available"><a ng-click="startDownload(item.download)">{{'DOWNLOAD_READY' | translate}}</a></span>
                    <span class="stacked-right yellow" data-ng-if="item.download.status == downloadStatus.processing">{{'PROCESSING_ORDER' | translate}}</span>
                    <span class="stacked-right yellow" data-ng-if="item.download.status == downloadStatus.onHold">{{'ON_HOLD' | translate}}</span>
                    <span class="stacked-right red" data-ng-if="item.download.status == downloadStatus.expired">{{'EXPIRED' | translate}}</span>
                </div>
            </div>

            <div data-ng-repeat="childItem in item.childItems">
                <div class="order-item-image-child">
                    <a data-ng-if="childItem.download && childItem.download.status == downloadStatus.available" ng-click="startDownload(childItem.download)"><img ng-src="{{childItem.pip.url}}" /></a>
                    <img data-ng-if="!childItem.download || childItem.download.status != downloadStatus.available" ng-src="{{childItem.pip.url}}" />
                </div>
                <div class="order-item-meta">
                    <p class="bold">{{childItem.name}}</p>
                    <p class="item-meta"><span ng-repeat="opt in childItem.options">{{opt.description}}<span ng-if="!$last">, </span></span></p>
                    <p class="item-meta margin-top-5 margin-bottom-5">{{childItem.images}}</p>
                    <span class="item-meta" ng-if="childItem.download">
                        <p data-ng-if="childItem.download.status == downloadStatus.available">{{'DOWNLOAD_STATUS' | translate}}: <a class="underline" ng-click="startDownload(childItem.download)">{{'DOWNLOAD_READY' | translate}} - {{'EXPIRES' | translate}} {{childItem.download.zipTimeout}}</a></p>
                        <div data-ng-if="childItem.download.status == downloadStatus.processing">{{'DOWNLOAD_STATUS' | translate}}: <p style="display: inline" class="yellow">{{'PROCESSING_ORDER' | translate}}</p></div>
                        <div data-ng-if="childItem.download.status == downloadStatus.onHold">{{'DOWNLOAD_STATUS' | translate}}: <p style="display: inline" class="yellow">{{'ON_HOLD' | translate}}</p></div>
                        <div data-ng-if="childItem.download.status == downloadStatus.expired">{{'DOWNLOAD_STATUS' | translate}}: <p style="display: inline" class="red">{{'EXPIRED' | translate}}</p></div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</load>