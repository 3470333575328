<div class="loginReminder modal-login-reminder">
    <div class="modal-text-block">
        <p ng-if="!isValidatedSession">{{'LOGIN_REMINDER_TEXT_FAVOURITE_NON_EMAIL_SESSION' | translate}}</p>
        <p ng-if="isValidatedSession">{{'LOGIN_REMINDER_TEXT_FAVOURITE_EMAIL_SESSION' | translate}}</p>
    </div>

    <div class="modal-button-box">
        <a class="button blue" ng-if="!isValidatedSession" ng-click="goToLogin()">{{'YES' | translate}}</a>
        <a class="button blue" ng-if="isValidatedSession" ng-click="goToRegister()">{{'YES' | translate}}</a>
        <a class="button blue" ng-click="continue()">{{'LOGIN_REMINDER_NO' | translate}}</a>
    </div>
</div>