<load wait-for="loadingPage">
    <span class="desktop-hide">
        <a class="button full-width" ng-show="!addEdit && !loading && addresses.length >= 2" ng-click="add()">{{'ADDADDRESS_BUTTON' | translate}}</a>
    </span>

    <div ng-show="!addEdit">
        <br /><br />

        <div class="desktop-hide" ng-repeat="address in addresses">
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'ADDRESS_FULLNAME' | translate}}</label>
                <span class="class-input">{{address.fullName}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'REGISTER_COUNTRY' | translate}}</label>
                <span class="class-input country">{{address.country.name}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'ADDRESS_POSTCODE' | translate}}</label>
                <span class="class-input">{{address.postcode}}</span>
            </div>
           <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'LINE_1' | translate}}</label>
                <span class="class-input">{{address.houseStreetFull}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'LINE_2' | translate}}</label>
                <span class="class-input">{{address.area}}</span>
            </div>
            <div class="form-group">
                <label class="class-label vertical-align-top bold">{{'TOWN' | translate}}</label>
                <span class="class-input">{{address.city}}</span>
            </div>
           <div class="form-group">
                <a class="button" ng-click="add(address)" ng-show="!addEdit" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_EDIT' | translate}}</a>
                <a class="button cancel" ng-click="delete(address.id)" ng-show="!addEdit" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_DELETE' | translate}}</a>
            </div>
            <br />
        </div>

        <div class="desktop-show" ng-show="addresses.length">
            <table>
                <thead>
                    <tr>
                        <th>{{'NAME' | translate}}</th>
                        <th>{{'REGISTER_COUNTRY' | translate}}</th>
                        <th>{{'ZIP' | translate}}</th>
                        <th>{{'LINE_1' | translate}}</th>
                        <th>{{'LINE_2' | translate}}</th>
                        <th>{{'TOWN' | translate}}</th>
                    </tr>
                </thead>
                <tbody ng-repeat="address in addresses">
                    <tr>
                        <td>{{address.fullName}}</td>
                        <td>{{address.country.name}}</td>
                        <td>{{address.postcode}}</td>
                        <td>{{address.houseStreetFull}}</td>
                        <td>{{address.area}}</td>
                        <td>{{address.city}}</td>
                        <td class="no-border">
                            <a class="button" ng-click="add(address)" ng-show="!addEdit" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_EDIT' | translate}}</a>
                        </td>
                        <td class="no-border">
                            <a class="button cancel" ng-click="delete(address.id)" ng-show="!addEdit" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_DELETE' | translate}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
        </div>

        <div ng-show="!addresses.length"><p>{{'NOADDRESSES_MESSAGE' | translate}}</p><br /></div>
        <a class="button add" ng-click="add()">{{'ADDADDRESS_BUTTON' | translate}}</a>
    </div>
</load>