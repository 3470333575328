<div class="order-items" data-ng-repeat="item in order.items">
    <img src="{{item.pip.url}}" style="width: 100px;" /> 
    <div class="right" style="margin-top: 30px">
        <span class="checkout-heading">
            {{item.name}}
        </span><br />
        <span style="font-size: 12px;">{{item.options}}</span>
    </div>
    <br />
    <p>{{'IMAGES' | translate}}: <span class="right">{{item.images}}</span></p>
    <p ng-show="item.description.itemNotes">{{'ITEM_NOTES' | translate}}: <span class="right">{{item.itemNotes}}</span></p>
    <p>{{'QUANTITY' | translate}}: <span class="right">{{item.quantity}}</span></p>
    <p>{{'PRICE' | translate}}:<span class="right">{{item.price}}</span></p>
    <p>{{'TOTAL' | translate}}: <span class="right">{{item.total}}</span></p>
    <br />
    <a class="button" ng-click="clone(item.itemId)" ng-disabled="loading"><i data-ng-show="loading" class="fa fa-spin fa-spinner"></i>&nbsp;{{'BUTTON_REORDER' | translate}}</a>
    <hr />
</div>