<div class="green product-note bold" style="margin-bottom: 10px" ng-show="basketDisable">
	{{ 'MODAL_ADDING_TO_BASKET_PROGRESS' | translate }}

	<div style="width:250px; margin: 5px auto;">
		<progress value="{{progressValue}}" max="{{progressMax}}"></progress>
	</div>
</div>

	<image-folders selected="currentAlbumId" modal="true"></image-folders>

<p data-ng-show="isLoading" class="loading-indicator fade"><i class="fa fa-spinner fa-spin"></i></p>

<div data-ng-show="!showFavourites && !isLoading">
	<h5 class="product-note margin-20">{{ 'PICTURE_PICKER_INSTRUCTIONS' | translate: { productName: productName } }}
		<a ng-disabled="modalBase.disableContinueAction()" ng-click="modalBase.continueAction()">{{ 'BUTTON_ADD_TO_BASKET' | translate }}</a>
	</h5>
	<div ng-if="!picture.exclude" data-ng-repeat="picture in (sitting.none.images || sitting.favourites.images)"
		 class="album-images modal-album-images">
		<div data-ng-click="togglePicture(picture)"
			 data-ng-disabled="basketDisable"
			 data-ng-style="{ 'background-image': 'url(' + picture.preview.url + ')' }"
			 data-ng-class="{ 'picture-selected': pictureIsSelected(picture) }"
			 class="album-image">
            <div ng-if="picture.basketCount && picture.basketCount >0" class="image-basket-count"><i class="fa fa-shopping-basket"></i><span>{{picture.basketCount}}</span></div>
			<div class="image-icons">
				<icon data-ng-show="picture.isFavourite" class="icon-heart full" type="heart"></icon>
			</div>

			<!--
			The $event.stopPropagation here prevents the image click event because fired when we click
			on the quantity amount.
			-->

		</div>
		<div ng-if="pictureIsSelected(picture) && showQuantityChangerElement()" class="quantity-changer">
			<a

			data-ng-disabled="basketDisable"
			data-ng-click="decreaseQuantityOfPictureChosen(picture, $event)" class="button">
				<i class="fa fa-minus"></i>
			</a>
			<span data-ng-click="$event.stopPropagation()" class="quantity-amt">{{picture.amountSelected}}</span>
			<a
			data-ng-disabled="basketDisable"
			data-ng-click="increaseQuantityOfPictureChosen(picture, $event)" class="button">
				<i class="fa fa-plus"></i>
			</a>
		</div>
		<p class="image-name">{{picture.imgFileName}} <a ng-click="displayLightbox(picture)"><i class="fa fa-search fa-2x"></i></a></p>
		<!--<div ng-if="picture.basketCount && picture.basketCount >0" class="image-basket-count"><i class="fa fa-shopping-basket"></i><span>{{picture.basketCount}}</span></div>-->
	</div>



	<!--this shows the disabled pictures at the bottom-->
	<div ng-if="picture.exclude" data-ng-repeat="picture in (sitting.none.images || sitting.favourites.images)"
		 class="album-images">
		<div data-ng-style="{ 'display': 'flex', 'flex-wrap': 'wrap', 'align-content': 'center', 'cursor': 'not-allowed'}"
			 data-ng-class="{ 'picture-selected': pictureIsSelected(picture) }"
			 class="album-image">
			<div data-ng-style="{ 'position': 'absolute', 'opacity': 0.4, 'background-image': 'url(' + picture.preview.url + ')', 'cursor': 'not-allowed' }" class="album-image"></div>
			<label class="picture-unavailable">{{'PICTURE_UNAVAILABLE' | translate}} - <a ng-click="displayUnavailableMessage(picture)">{{'WHY' | translate}}</a></label>
			<div class="image-icons">
				<icon data-ng-show="picture.isFavourite" class="icon-heart full" type="heart"></icon>
				<icon data-ng-hide="picture.isFavourite" class="icon-heart" type="heart-empty"></icon>
			</div>
		</div>
		<p class="image-name">{{picture.imgFileName}}</p>
	</div>
</div>

<div class="picture-unavailable-message" ng-show="showUnavailableMessage">
	<div class="picture-unavailable-message-content">
		<a data-ng-click="closeUnavailableMessage()" class="button cancel-button close"><i class="fa fa-close"></i></a>
		<div>
			<p ng-bind-html="'PICTURE_UNAVAILABLE_MESSAGE' | translate: {productName: productName}"></p>
			<div class="modal-button-box">
				<a class="button blue" ng-click="closeUnavailableMessage()">{{'OK' | translate}}</a>
			</div>
		</div>
	</div>
</div>

<div class="lightbox" ng-show="showLightbox">
	<div class="lightbox-image-container">
		<a data-ng-click="closeLightbox()" class="button cancel-button close"><i class="fa fa-close"></i></a>
		<img id="lightboxImg" src="{{lightboxImg.lowRes.url}}" />
	</div>
</div>